<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Permissions</span>
        </template>
        <template #body>
            <div class="sections-wrap">
                <div
                    v-for="section in sections"
                    :key="section.id"
                    class="mb-3 section"
                >
                    <h4>{{ section.name }}:</h4>
                    <v-chip
                        v-for="(item, itemIndex) in section.items"
                        :key="itemIndex"
                        class="mt-4 mr-4 name"
                        color="#EFF5FC"
                        text-color="#1976d2"
                    >
                        {{ item }}
                    </v-chip>
                </div>
            </div>
        </template>
        <template #actions>
            <v-card-actions
                class="pb-5 pt-0 px-5"
            >
                <v-btn
                    color="accent"
                    class="py-4 px-3 mr-4"
                    height="max-content"
                    @click="$emit('closeModal', true)"
                >
                    Ok
                </v-btn>
            </v-card-actions>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    name: 'PermissionsModal',
    components: {
        Modal
    },
    props: {
        permissions: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            showModal: true
        }
    },
    computed: {
        sections() {
            return this.permissions.reduce((acc, { name }) => {
                const arr = name.split('/')
                const currentItem = acc.find((item) => item.name === arr[0]) || { name: arr[0], items: [] }
                currentItem.items.push(name)
                if (currentItem.items.length === 1) {
                    acc.push(currentItem)
                }
                return acc
            }, [])
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', true)
        }
    }
}
</script>

<style>
    .sections-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
    }
    h4{
        font-size: 18px;
        text-transform: capitalize;
    }
    .section > .name {
        font-size: 16px;
        padding: 10px;
    }
</style>
