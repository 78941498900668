import {
    email,
    required
} from 'vuelidate/lib/validators';

import passwordRules from '../password/Rules.js'

export const validationRules = {
    email: {
        required,
        email
        // async emailExists(value) {
        //     // ignore this validator, if any of the previous fail
        //     if (!this.$v.email.required || !this.$v.email.email) { return true; }

        //     return new Promise((resolve) => {
        //         const vm = this
        //         this.checkEmail({ email: value })
        //             .then(() => {
        //                 resolve(true)
        //             })
        //             .catch(() => {
        //                 vm.$v.email.$params.emailExists = true
        //                 resolve(false)
        //             })
        //     })
        // }
    },
    password: { ...passwordRules },
    code: {
        async isValidCode() {
            if (!this.showOtp) {
                return true
            }
            return this.correctOTP
        }
    }
}
