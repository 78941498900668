export const TOOLBARS = [
    {
        name: 'activate',
        text: 'Activate',
        modal: {
            name: 'ModalAlert',
            title: 'Activate the writer',
            description: 'Do you really want to activate selected writer?',
            success_message: 'The writer is activated'
        },
        icon: 'mdi-checkbox-marked-circle-outline'
    },
    {
        name: 'deactivate',
        text: 'Deactivate',
        modal: {
            name: 'ModalAlert',
            title: 'Writers deactivation',
            description: 'Do you want to deactivate selected writer?',
            success_message: 'The writer is deactivated'
        },
        icon: 'mdi-close-circle-outline'
    },
    // {
    //     name: 'edit_writer',
    //     text: 'Edit',
    //     modal: {
    //         name: 'ModalWriterEdit',
    //         title: 'Edit the writer',
    //         success_message: 'Writer data edited'
    //     },
    //     icon: 'mdi-pencil'
    // },
    // {
    //     name: 'upload_files',
    //     text: 'Upload',
    //     modal: {
    //         name: 'ModalUpload',
    //         description: 'Supported only .jpg, .png, .pdf, .doc, .docx, .pdf, extensions. You can upload files up to 30 MB in size.',
    //         success_message: 'The file(s) is uploaded.',
    //         filesKey: 'file[]',
    //         typeKey: 'status'
    //     },
    //     icon: 'mdi-upload-outline'
    // },
    {
        name: 'notes',
        text: 'Note',
        modal: {
            name: 'ModalTextarea',
            title: 'Post a note',
            description: "Post a note to writer's profile.",
            inputLabel: 'Details',
            success_message: 'The note has been posted'
        },
        icon: 'mdi-note-outline'
    },
    {
        name: 'contact_writer',
        text: 'Contact writer',
        modal: {
            name: 'ModalCreateTicketWriter',
            success_message: 'The support ticket is created.'
        },
        icon: 'mdi-account-circle-outline'
    },
    {
        name: 'edit_limit',
        text: 'Update',
        modal: {
            name: 'ModalWriterUpdate',
            title: 'Update writer info',
            description: 'Enter a new limit value',
            success_message: 'Writer info has been update'
        },
        icon: 'mdi-briefcase-edit-outline'
    },
    {
        name: 'credits',
        text: 'Add a credit or debit',
        modal: {
            name: 'ModalWriterCredits',
            title: 'Add a credit or debit',
            success_message: 'The credit(debit) has been added'
        },
        icon: 'mdi-cash-multiple'
    },
    {
        name: 'statistic_refresh',
        text: 'Refresh',
        modal: {
            name: 'ModalAlert',
            title: 'Update the data',
            description: 'Do you really want to update the data about the writer?',
            success_message: 'The writer is updated'
        },
        icon: 'mdi-refresh-auto'
    }
]

export const TOOLBAR_APPLICANT = [
    {
        name: 'applicant_approve',
        text: 'Approve applicant',
        modal: {
            name: 'ModalAlert',
            title: 'Approve applicant',
            description: 'Do you really want to approve selected applicant?',
            success_message: 'The applicant is approved'
        },
        icon: 'mdi-checkbox-marked-circle-outline'
    },
    {
        name: 'applicant_deactivate',
        text: 'Deactivate applicant',
        modal: {
            name: 'ModalAlert',
            title: 'Applicant deactivation',
            description: 'Do you want to deactivate selected applicant?',
            success_message: 'The applicant is deactivated'
        },
        icon: 'mdi-close-circle-outline'
    },
    {
        name: 'notes',
        text: 'Note',
        modal: {
            name: 'ModalTextarea',
            title: 'Post a note',
            description: "Post a note to writer's profile.",
            inputLabel: 'Details',
            success_message: 'The note has been posted'
        },
        icon: 'mdi-note-outline'
    },
    {
        name: 'contact_writer',
        text: 'Contact applicant',
        modal: {
            name: 'ModalCreateTicketWriter',
            success_message: 'The support ticket is created.'
        },
        icon: 'mdi-account-circle-outline'
    },
    {
        name: 'statistic_refresh',
        text: 'Refresh',
        modal: {
            name: 'ModalAlert',
            title: 'Update the data',
            description: 'Do you really want to update the data about the writer?',
            success_message: 'The writer is updated'
        },
        icon: 'mdi-refresh-auto'
    }
]

export const NOTE_TOOLBARS = [
    {
        name: 'edit_note',
        text: 'Edit',
        modal: {
            name: 'ModalTextarea',
            title: 'Edit note',
            description: 'Edit the selected note',
            inputLabel: 'Details',
            success_message: 'The note has been edited'
        },
        icon: 'mdi-pencil'
    },
    {
        name: 'delete_note',
        text: 'Delete',
        modal: {
            name: 'ModalAlert',
            title: 'Delete note',
            description: 'Delete the note?',
            success_message: 'The note has been deleted'
        },
        icon: 'mdi-close-circle-outline'
    }
]
