<template>
    <Viewer
        :title="'Notes'"
        @closeSidePanel="closeNotes"
    >
        <template #body>
            <div v-if="notes && notes.length">
                <v-row class="pt-5">
                    <v-col
                        cols="12"
                    >
                        <template
                            v-for="(item, index) in notes"
                        >
                            <div
                                :key="index"
                            >
                                <div
                                    v-if="item.owner"
                                    class="text-subtitle-2 text-capitalize pl-13"
                                >
                                    {{ item.owner.firstname }}
                                    {{ item.owner.lastname }},
                                    <span class="text-body-1 grey--text">
                                        <!-- role: {{ item.admin.role }}, -->
                                        id: {{ item.owner.id }}
                                    </span>
                                </div>
                                <div
                                    class="d-flex flex-grow-1"
                                >
                                    <avatar-rolles
                                        v-if="item.owner"
                                        access-status="ADMIN"
                                        :firstname="item.owner.firstname"
                                        :lastname="item.owner.lastname"
                                        :avatar-url="item.owner.avatar_url"
                                    />
                                    <div class="d-flex flex-column ml-2 flex-grow-1">
                                        <div
                                            class="notes-detail pa-5"
                                        >
                                            <div>
                                                {{ item.message }}
                                            </div>
                                        </div>
                                        <p class="grey--text mt-2 mb-5">
                                            {{ $date.dateFromNow(item.ts_issued) }},
                                            {{ $date.humanizeDate(item.ts_issued) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div
                            v-if="notes && !notes.length"
                            class="d-flex flex-column align-center w-full mt-5 mb-5"
                        >
                            <div class="">
                                <img
                                    src="@images/svg/no-data.svg"
                                    alt=""
                                >
                            </div>
                            <p class="text-h6">
                                No note found
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </template>
    </Viewer>
</template>

<script>

import Viewer from '@/components/SidePanels/Viewer'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles';

export default {
    components: {
        Viewer,
        AvatarRolles
    },
    props: {
        notes: {
            type: Array,
            required: true
        }
    },
    methods: {
        closeNotes() {
            this.$emit('closeNotes', true)
        }
    }
}
</script>

<style lang="scss">
    .notes-detail{
        position: relative;
        background: #F4F8FD;
        border: 1px solid #EFEFEF;
        .action_block{
            position: absolute;
            top: 10px;
            right: 10px;
            bottom: initial;
            left: initial;
            transform: initial;
            opacity: 0;
            .resolved-btn{
                display: flex;
                align-items: center;
                font-size: 12px;
            }
        }
        &:hover{
            .action_block{
                opacity: 1;
            }
        }
    }
</style>
