<template>
    <div class="auth-form">
        <div class="auth-form__header py-8 text-center">
            <slot name="header">
                <span>CRM Login</span>
            </slot>
        </div>
        <div class="auth-form__body pt-10 pb-8 px-10">
            <slot name="body">
                <span>Auth Form</span>
            </slot>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
    .auth-form{
        &__header{
            position: relative;
            border-bottom: 1px solid #D3D9E2;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 3px;
                background: #284067;
            }
        }
    }
</style>
