<template>
    <v-form @submit.prevent="submit">
        <v-text-field
            v-model="email"
            :error-messages="validationMessage($v.email)"
            :readonly="readonly"
            label="Email"
            outlined
            color="accent"
            prepend-inner-icon="mdi-email"
            clearable
            @focus="readonly=false"
            @keydown="spacesNotAllowed"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
        />
        <v-text-field
            v-model="password"
            label="Password"
            :readonly="readonly"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
            color="accent"
            prepend-inner-icon="mdi-lock"
            :error-messages="validationMessage($v.password)"
            @click:append="showPassword = !showPassword"
            @focus="readonly=false"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
        />
        <!-- OTP -->
        <v-text-field
            v-if="showOtp"
            v-model="code"
            :readonly="readonly"
            outlined
            color="accent"
            label="6-digit code"
            :rules="[rules.required, rules.minLengthCode, rules.maxLengthCode, validCode ]"
        />
        <!-- OTP -->
        <p>
            Forgot your password?
            <router-link
                :to="{name: 'reset-password'}"
                class="accent--text"
            >
                Reset
            </router-link>
            or
            <router-link
                :to="{name: 'register'}"
                class="accent--text"
            >
                Register Account
            </router-link>
        </p>
        <div>
            <v-checkbox
                v-model="remember_me"
                label="Remember me on this computer"
                color="accent"
                hide-details
            />
        </div>
        <div class="mt-8">
            <v-btn
                color="accent"
                class="py-4"
                min-width="100%"
                height="max-content"
                type="submit"
                :loading="loading"
            >
                Sign in
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import { validationMessage } from 'vuelidate-messages'

import { validationRules } from '~/validation/login/Rules'
import { formMessages } from '~/validation/login/Messages'

export default {
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        showOtp: {
            type: Boolean,
            required: false
        },
        correctOtp: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            showPassword: false,
            remember_me: false,
            email: '',
            password: '',
            readonly: true,
            // otp
            code: null,
            rules: {
                required: (value) => !!value || 'Required.',
                minLengthCode: (value) => value.length > 5 || 'The code must be at least 6 characters.',
                maxLengthCode: (value) => value.length < 7 || 'The subject must not be greater than 6 characters.'
            },
            validCode: true
        }
    },
    validations: {
        ...validationRules
    },
    watch: {
        correctOtp() {
            this.validCode = this.correctOtp || 'Incorrect code'
        },
        code() {
            if (!this.correctOtp) {
                this.$emit('updateValidateCode')
                this.$nextTick(() => {
                    this.validCode = this.correctOtp || 'Incorrect code'
                })
            }
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        submit() {
            this.$v.$touch()
            this.$emit('submit', {
                email: this.email,
                password: this.password,
                remember_me: this.remember_me,
                one_time_password: this.code
            })
        },
        spacesNotAllowed(e) {
            if (e.code === 'Space') {
                e.preventDefault()
            }
        }
    }
}
</script>
