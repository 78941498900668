<template>
    <v-data-table
        :headers="tableHeader"
        :items="getterPayoutsList"
        calculate-widths
        hide-default-footer
        :items-per-page="-1"
        :sort-by="searchOptions.sort_by"
        :sort-desc="sortDesc"
        must-sort
        :server-items-length="getterPayoutsTotal"
        @update:options="$emit('updateOptions', $event)"
    >
        <template #header="{ }">
            <thead
                v-if="!mobile"
                class="v-data-table-header"
            >
                <tr>
                    <th
                        v-for="h in tableHeader"
                        :key="h.value"
                    >
                        <template v-if="h.value === 'amount'">
                            <div class="d-flex align-center">
                                <div class="mr-4">
                                    <p class="mb-0 grey--text">
                                        Total amount:
                                    </p>
                                    <p class="mb-0">
                                        <v-progress-circular
                                            v-if="getterPayoutsTotalsLoading"
                                            ref="loader"
                                            :size="14"
                                            color="primary"
                                            indeterminate
                                        />
                                        <b v-else>{{ getterPayoutsSummary.total_amount | money }}</b>
                                    </p>
                                </div>
                            </div>
                        </template>
                        <template v-if="h.value === 'comments'">
                            <div class="mr-4">
                                <p class="mb-0 grey--text">
                                    Total count:
                                </p>
                                <p class="mb-0">
                                    <v-progress-circular
                                        v-if="getterPayoutsTotalsLoading"
                                        ref="loader"
                                        :size="14"
                                        color="primary"
                                        indeterminate
                                    />
                                    <b v-else>{{ getterPayoutsSummary.count | number }}</b>
                                </p>
                            </div>
                        </template>
                        <template v-if="h.value === 'pay_method'">
                            <div
                                class="mr-4"
                            >
                                <p class="mb-0 grey--text">
                                    Payoneer Account balance:
                                </p>
                                <p
                                    v-if="payoneerBalance"
                                    class="mb-0"
                                >
                                    <v-progress-circular
                                        v-if="loadingPayoneerBalance"
                                        ref="loader"
                                        :size="14"
                                        color="primary"
                                        indeterminate
                                    />
                                    <b v-else>{{ payoneerBalance.currency }} {{ payoneerBalance.balance }}</b>
                                </p>
                                <b v-else>-</b>
                            </div>
                        </template>
                    </th>
                </tr>
            </thead>
            <thead v-else>
                <tr>
                    <th class="d-flex align-center">
                        <div class="d-flex align-center">
                            <div class="mr-4">
                                <p class="mb-0 grey--text">
                                    Total amount:
                                </p>
                                <p class="mb-0">
                                    <v-progress-circular
                                        v-if="getterPayoutsTotalsLoading"
                                        ref="loader"
                                        :size="14"
                                        color="primary"
                                        indeterminate
                                    />
                                    <b v-else>{{ getterPayoutsSummary.total_amount | money }}</b>
                                </p>
                            </div>
                        </div>
                        <div class="mr-4">
                            <p class="mb-0 grey--text">
                                Total count:
                            </p>
                            <p class="mb-0">
                                <v-progress-circular
                                    v-if="getterPayoutsTotalsLoading"
                                    ref="loader"
                                    :size="14"
                                    color="primary"
                                    indeterminate
                                />
                                <b v-else>{{ getterPayoutsSummary.count | number }}</b>
                            </p>
                        </div>
                        <div class="mr-4">
                            <p class="mb-0 grey--text">
                                Payoneer Account balance:
                            </p>
                            <p
                                v-if="payoneerBalance"
                                class="mb-0"
                            >
                                <v-progress-circular
                                    v-if="loadingPayoneerBalance"
                                    ref="loader"
                                    :size="14"
                                    color="primary"
                                    indeterminate
                                />
                                <b v-else>{{ payoneerBalance.currency }} {{ payoneerBalance.balance }}</b>
                            </p>
                            <b v-else>-</b>
                        </div>
                    </th>
                </tr>
            </thead>
        </template>
        <template v-slot:body="{ items }">
            <table-skeleton
                v-if="getterPayoutsListLoading && items.length === 0"
                :per-page="searchOptions.per_page"
                :column-count="tableHeader.length"
            />
            <table-loader
                v-if="getterPayoutsListLoading"
            />
            <tbody>
                <template v-for="item in items">
                    <tr
                        :key="item.id"
                        class="hovered-row"
                        :class="rowClasses(item.id)"
                        @mouseenter="selectItem(item.id)"
                        @mouseleave="unSelectItem(item.id)"
                    >
                        <!-- DATE -->
                        <td>
                            <div class="d-flex align-center">
                                <div>{{ $date.dateFromNow(item.created_at) }}</div>
                                <information-tooltip>
                                    <span>{{ $date.fullDate(item.created_at) }}</span>
                                </information-tooltip>
                            </div>
                        </td>
                        <!-- WRITER ID -->
                        <td
                            class="td_user"
                            align="center"
                        >
                            <div class="relative">
                                <writer-avatar
                                    v-if="item.writer"
                                    :id="item.writer.sw_id"
                                    :firstname="item.writer.firstname"
                                    :lastname="item.writer.lastname"
                                    :feedbacks="item.writer.statistics_parsed && item.writer.statistics_parsed.rank.rank_all_count"
                                    :rating="item.writer.statistics_parsed && item.writer.statistics_parsed.rank.rank_all"
                                    :rating_rc="item.writer.statistics_parsed && item.writer.statistics_parsed.rank.rank_thirty_days"
                                    :avatar-url="item.writer.avatar_url"
                                    :avatar-fallback="item.writer.avatar_fallback"
                                    :country="item.writer.country"
                                    :country-nice-name="item.writer.country_nice_name"
                                    :status="item.writer.trust_status"
                                />
                            </div>
                        </td>

                        <td
                            class="td_user"
                            style="position: sticky; left: 0;z-index: 9; background-color: white;"
                        >
                            <div
                                v-if="item.writer"
                            >
                                <p class="mb-0 font-weight-bold">
                                    {{ item.writer.firstname }} {{ item.writer.lastname }}
                                </p>
                                <p
                                    v-if="item.writer.sw_id"
                                    class="mb-0"
                                >
                                    {{ item.writer.sw_id }}
                                </p>
                            </div>
                            <div
                                class="d-flex align-center justify-space-between"
                                style="width: 200px; height: 30px"
                            >
                                <div>
                                    <!-- notes -->
                                    <span
                                        v-if="item.notes.length> 0"
                                        class="badge"
                                        style="cursor: pointer;"
                                        @click="showPayrollNote(item)"
                                    >
                                        <b>{{ item.notes.length }}</b> {{ `${item.notes.length === 1 ? 'note' : 'notes'}` }}
                                    </span>
                                    <!-- notes -->
                                </div>
                                <div
                                    class="show-details-tools"
                                    :class="{
                                        'show-details-tools--open': hoveredItem === item.id || openedRow.includes(item.id)
                                    }"
                                >
                                    <!-- view -->
                                    <span
                                        class="white--text tw-rounded tw-px-2 tw-py-1 hover:tw-bg-blue-700"
                                        style="background-color: #1976d2; font-size: 12px; cursor: pointer"
                                        @click="showPayrollDetails(item)"
                                    >
                                        view
                                    </span>
                                    <!-- view -->
                                    <table-rows-menu
                                        :toolbars="getItemToolbars(item)"
                                        :item="item"
                                        list-type="orders"
                                        :color="'primary'"
                                        @menuActivated="onMenuActivated"
                                        @hoverRow="onHoverRow"
                                    />
                                </div>
                            </div>
                        </td>

                        <!-- METHOD -->
                        <td>
                            <div class="d-flex items-center">
                                <span>
                                    {{ payMethod(item) }}
                                </span>
                                <v-menu
                                    top
                                    color="primary"
                                    :offset-y="true"
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <div
                                            class="d-inline-block order-title text-subtitle-2 font-weight-regular text-truncate bg-primary"
                                        >
                                            <v-icon
                                                color="primary"
                                                class="px-2 pb-1 information"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                mdi-information-variant
                                            </v-icon>
                                        </div>
                                    </template>
                                    <div
                                        v-if="item.paymethod_data_parsed"
                                        class="primary text--white py-2"
                                    >
                                        <!-- <v-btn
                                            class="close-btn"
                                            dark
                                            fab
                                            x-small
                                        >
                                            <v-icon
                                                dark
                                                small
                                            >
                                                mdi-close
                                            </v-icon>
                                        </v-btn> -->
                                        <template
                                            v-for="(detail, index) in getPayMethodDetails(item.paymethod_data_parsed.details)"
                                        >
                                            <span
                                                :key="index"
                                                class="custom-selection text-capitalize px-3"
                                            >
                                                <span>{{ detail }}</span>
                                                <br>
                                            </span>
                                        </template>
                                    </div>
                                </v-menu>
                            </div>
                        </td>

                        <!-- PAYMENT ID -->
                        <td>
                            <copy-to-click-board
                                v-if="item.paymentid"
                                :copy-string="String(item.paymentid)"
                            />
                            <span v-else>
                                -
                            </span>
                        </td>
                        <!-- RECEIPT -->
                        <td>
                            <div v-if="item.receipt">
                                <v-btn
                                    fab
                                    dark
                                    x-small
                                    color="primary"
                                    @click="openReceipt(item.receipt)"
                                >
                                    <v-icon dark>
                                        mdi-receipt-text
                                    </v-icon>
                                </v-btn>
                            </div>
                            <span v-else>
                                -
                            </span>
                        </td>

                        <!-- STATUS -->
                        <td>
                            <div class="d-flex align-item">
                                <v-chip :color="statusBgColor(item.status)">
                                    <span :style="`color: ${statusTextColor(item.status)}`">
                                        {{ item.status }}
                                    </span>
                                </v-chip>
                                <v-btn
                                    v-if="item.status === 'REQUEST'"
                                    class="ml-2 payout-btn"
                                    fab
                                    dark
                                    x-small
                                    color="primary"
                                    @click="payoutProceed(item.paymentid)"
                                >
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="item.status === 'PENDING' && (payMethod(item) === 'payoneer' || payMethod(item) === 'wire' || payMethod(item) === 'mpesa')"
                                    class="ml-2 payout-btn"
                                    fab
                                    dark
                                    x-small
                                    color="primary"
                                    @click="pendingProceed(item.paymentid, payMethod(item))"
                                >
                                    <v-icon dark>
                                        mdi-currency-usd
                                    </v-icon>
                                </v-btn>
                            </div>
                        </td>
                        <!-- WRITER STATUS -->
                        <td>
                            <v-chip
                                v-if="item.sw_status"
                                color="#27D91F1A"
                            >
                                <span class="success--text">
                                    {{ item.sw_status }}
                                </span>
                            </v-chip>
                            <span v-else>
                                -
                            </span>
                        </td>
                        <!-- amount -->
                        <td>
                            <div class="d-flex justify-end font-weight-bold">
                                {{ item.amount | money }}
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
// import { eventBus } from '@/helpers/event-bus/'
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableLoader from '@/components/shared/Tables/TableLoader.vue';
import InformationTooltip from '@/components/shared/InformationTooltip';
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard';
import {
    WRITERS_PAYMENTS_TABLE_HEADER
} from '@/constants/Writers'
import filterMixin from '@/mixins/filtersMixin'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar.vue';
import { statusBgColor, statusTextColor } from '@/helpers/order/statusHelper'
import { getPayMethodDetails } from '@/helpers/payMethodsParses'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu.vue';
import { PAYROLL_TOOLBARS } from '@/constants/Payroll';

export default {
    components: {
        TableRowsMenu,
        TableSkeleton,
        TableLoader,
        InformationTooltip,
        CopyToClickBoard,
        WriterAvatar
    },
    mixins: [
        filterMixin
    ],
    props: {
        searchOptions: {
            type: Object,
            required: true
        },
        sortDesc: {
            type: Boolean,
            required: true
        },
        payoneerBalance: {
            type: Object
        },
        loadingPayoneerBalance: {
            type: Boolean
        }
    },
    data() {
        return {
            openedRow: [],
            mobile: false,
            hoveredItem: null,
            openTools: false
        }
    },
    computed: {
        ...mapGetters('writers', [
            'getterPayoutsList',
            'getterPayoutsTotal',
            'getterPayoutsLastPage',
            'getterPayoutsListLoading',
            'getterPayoutsPresets',
            'getterPayoutsPresetsLoading',
            'getterPayoutsSummary',
            'getterPayoutsTotalsLoading'
        ]),
        tableHeader() {
            return WRITERS_PAYMENTS_TABLE_HEADER
        }
    },
    created() {
        if (window.innerWidth <= 599) this.mobile = true
    },
    methods: {
        statusBgColor,
        statusTextColor,
        getPayMethodDetails,
        rowClasses(id) {
            return {
                'table-row--opened': this.openedRow.includes(id)
            }
        },
        extarsRowClasses(item) {
            return {
                'table-extras-row--opened': this.openedRow.includes(item.session_id)
            }
        },
        payoutProceed(paymentid) {
            this.$emit('payoutProceed', paymentid)
        },
        pendingProceed(paymentid, paymentType) {
            this.$emit('pendingProceed', paymentid, paymentType)
        },
        payMethod(item) {
            return item.paymethod_data_parsed?.type ?? '-'
        },
        showPayrollDetails(item) {
            this.$emit('ShowPayrollDetails', item)
        },
        showPayrollNote(item) {
            this.$emit('ShowPayrollNote', item)
        },
        openReceipt(receipt) {
            this.$emit('openReceipt', receipt)
        },
        onHoverRow(val) {
            this.openTools = val
        },
        onMenuActivated(item, modal) {
            this.$emit('actionRow', item, modal)
        },
        getItemToolbars() {
            return PAYROLL_TOOLBARS
        },
        selectItem(item) {
            this.hoveredItem = item
        },
        unSelectItem() {
            this.hoveredItem = null
        }
    }
}
</script>
<style lang="scss" scoped>
    .close-btn {
        position: absolute;
        right: -10px;
        top: -10px;
        background-color: #1976d2 !important;
        border: 2px solid #fff;
    }
    .v-menu__content {
        contain: none;
        overflow: visible;
    }
    .custom-selection {
        span::selection {
            background: #59C74D;
        }
    }
    .user_orders_count{
        position: relative;
        &__counter{
            position: absolute;
            top: 0;
            left: 30px;
            background: #59C74D;
            border-radius: 4px;
            color: #fff;
            padding: 3px 4px;
            font-weight: bold;
            font-size: 12px;
            text-align: center;
            line-height: 1;
        }
    }
    .relative{
        position: relative;
    }
    .avatar_rank--check{
        position: absolute !important;
        bottom: 0;
        right: -5px;
        width: 17px;
        height: 17px;
        background: rgba(89, 199, 77, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .badge{
        white-space: nowrap;
        font-size: 12px;
        padding: 4px 8px;
        background: #D3D9E2;
        border-radius: 4px;
        &+& {
            margin-left: 12px;
        }
    }

    .show-details-tools{
      display: none;
    }
    .show-details-tools--open {
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: #e5f4ff;
      border-radius: 4px;
      padding: 2px;
    }
</style>
