<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Edit the payroll</span>
        </template>
        <template #body>
            <v-form>
                <template v-for="field in editFields">
                    <v-text-field
                        :key="field.name"
                        :label="field.name"
                        outlined
                    />
                </template>
            </v-form>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    components: {
        Modal
    },
    data() {
        return {
            showModal: true
        }
    },
    computed: {
        editFields() {
            return [
                {
                    name: 'field_id'
                },
                {
                    name: 'session_id'
                },
                {
                    name: 'utc_created'
                },
                {
                    name: 'utc_due'
                },
                {
                    name: 'field_id'
                },
                {
                    name: 'domain'
                },
                {
                    name: 'field_id2'
                },
                {
                    name: 'field_id3'
                }
            ]
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', true)
        }
    }
}
</script>

<style>

</style>
