import { TOOLBARS } from './permissions-toolbar'

export const PERMISSIONS_TABLE_HEADER = [
    {
        text: 'ID',
        value: 'id',
        sortable: false
    },
    {
        text: 'Name',
        value: 'name',
        sortable: false
    },
    {
        text: 'Created',
        value: 'created_at',
        sortable: false
    },
    {
        text: '',
        class: 'action_block',
        value: 'actions',
        sortable: false
    }
]

export const PERMISSIONS_ITEM_TOOLBARS = [
    {
        name: 'delete',
        text: 'Delete',
        modal: {
            name: 'ModalAlert',
            title: 'Remove the permission',
            description: 'Do you really want to remove the permission?'
        },
        icon: 'mdi-trash-can-outline'
    },
    {
        name: 'edit',
        text: 'Edit',
        modal: {
            name: 'ModalPermission',
            title: 'Edit the permission'
        },
        icon: 'mdi-pencil'
    }
]

export const PERMISSIONS_TOOLBARS = TOOLBARS
