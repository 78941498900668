export const TOOLBARS = [
    {
        name: 'activated',
        text: 'Activated',
        modal: {
            name: 'ModalAlert',
            title: 'Admin(s) activation',
            description: 'Do you really want to activate the admin?',
            success_message: 'The admin was activated'
        },
        icon: 'mdi-lock-open'
    },
    {
        name: 'deactivated',
        text: 'Deactivated',
        modal: {
            name: 'ModalAlert',
            title: 'Admin(s) deactivation',
            description: 'Do you really want to deactivate the admin?',
            success_message: 'The admin was deactivated'
        },
        icon: 'mdi-lock'
    },
    {
        name: 'change_avatar',
        text: 'Change avatar',
        modal: {
            name: 'ModalAvatarUpload',
            title: 'Change the admin avatar',
            description: 'Change the admin avatar',
            success_message: 'The admin photo has been changed'
        },
        icon: 'mdi-account-reactivate-outline'
    }
]
