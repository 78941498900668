export const ADMIN_LIST_SEARCH = {
    search_by: {
        value: 'search_by',
        cols: 12,
        list: [
            {
                text: 'Firstname',
                value: 'firstname'
            },
            {
                text: 'Lastname',
                value: 'lastname'
            },
            {
                text: 'Email',
                value: 'email'
            }
        ]
    }
}
