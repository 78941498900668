export const TOOLBARS = [
    {
        name: 'notes',
        text: 'Note',
        modal: {
            name: 'ModalTextarea',
            title: 'Post a note',
            description: 'Post a note',
            inputLabel: 'Details',
            success_message: 'The note has been posted'
        },
        icon: 'mdi-note-outline'
    }
]
