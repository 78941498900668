<template>
    <v-form @submit.prevent="validate">
        <div class="phone-number__form">
            <v-autocomplete
                v-model="phone_code"
                :items="countries"
                outlined
                :filter="codeFilter"
                color="accent"
                label="Code"
                class="phone-number__form-code"
                :error-messages="validationMessage($v.phone_code)"
                @input="$v.phone_code.$touch()"
                @blur="$v.phone_code.$touch()"
            >
                <template
                    slot="selection"
                    slot-scope="{ item }"
                >
                    <span :class="'flag-icon flag-input-icon flag-icon-' + item.iso.toLowerCase()" />
                    +{{ item.value }}
                </template>
                <template
                    slot="item"
                    slot-scope="{ item }"
                >
                    <span :class="'flag-icon flag-input-icon flag-icon-' + item.iso.toLowerCase()" />
                    {{ item.text }} (+{{ item.value }})
                </template>
            </v-autocomplete>
            <v-text-field
                v-model.number="phone_number"
                label="Phone Number"
                pattern="[0-9]*"
                outlined
                color="accent"
                clearable
                class="phone-number__form-number"
                :error-messages="validationMessage($v.phone_number)"
                @input="$v.phone_number.$touch()"
                @blur="$v.phone_number.$touch()"
            />
            <RecaptchaButton
                :validate-params="$v.$invalid"
                @checkField="verificationStart"
                @captchaVerify="verificationStart"
            >
                <template slot="button-content">
                    <v-btn
                        v-if="phone_validation_loading"
                        color="accent"
                        height="56px"
                        class="phone-number__form-btn"
                        :outlined="true"
                        loading
                    />
                    <v-btn
                        v-else
                        :outlined="true"
                        :disabled="verifyBtnDisabled"
                        color="accent"
                        height="56px"
                        class="phone-number__form-btn"
                        type="submit"
                    >
                        verify
                    </v-btn>
                </template>
            </RecaptchaButton>
        </div>
    </v-form>
</template>

<script>
import { validationMessage } from 'vuelidate-messages'

import Api from '~/helpers/api/index.js'

import { validationRules } from '~/validation/phone/Rules'
import { formMessages } from '~/validation/phone/Messages'
import { eventBus } from '@/helpers/event-bus/'

import RecaptchaButton from '@/components/shared/RecaptchaButton';

export default {
    components: {
        RecaptchaButton
    },
    props: {
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            countries: [],
            phone_code: '',
            phone_number: '',
            phoneValidationStatus: false,
            phone_validation_loading: false
        }
    },
    validations: {
        ...validationRules
    },
    computed: {
        verifyBtnDisabled() {
            return this.$v.$invalid
        }
    },
    async created() {
        await this.getCountries()
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async getCountries() {
            const { data } = await Api.get('/api/data/countries')
            this.countries = data
        },
        // verifyNumber() {
        //     this.$v.$touch()
        //     if (this.$v.$invalid) {
        //         return
        //     }
        //     this.varificationLoading = true
        //     this.$emit('verifyNumber', {
        //         phone_code: this.phone_code,
        //         phone: this.phone_number
        //     })
        // },
        codeFilter(item, queryText) {
            const textOne = `${item.value}`.toLowerCase()
            const textTwo = item.text.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        },
        validate() {
            this.$v.$touch()
        },
        async verificationStart(payload) {
            if (this.$v.$invalid) {
                await this.isValid()
                const errorName = this.findNameOfFirstError(this.$v);
                eventBus.$emit('showSnackBar', `Please complete the form: ${errorName}`, 'error');
            } else {
                this.phone_validation_loading = true
                Api.post('/api/phone-verification/send-pin', {
                    phone: this.phone_number,
                    phone_code: this.phone_code,
                    recaptcha: payload
                }).then(() => {
                    // this.$store.commit('phone/START')
                    this.phone_validation_error = false
                    eventBus.$emit('showSnackBar', 'Check your mobile for the PIN', 'success');
                    this.$emit('onVerifyNumber', {
                        phone: this.phone_number,
                        phone_code: this.phone_code
                    })
                }).catch((error) => {
                    eventBus.$emit('showSnackBar', error, 'error');
                    this.phone_validation_error = true
                    this.phone_validation_message = error
                    setTimeout(() => {
                        this.phone_validation_error = false
                        this.phone_validation_message = ''
                    }, 2000)
                }).finally(() => {
                    this.phone_validation_loading = false
                })
            }
        }
    }
}
</script>

<style>

</style>
