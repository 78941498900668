<template>
    <v-form @submit.prevent="verifyCode">
        <div class="phone-number__code">
            <v-text-field
                v-model="pin"
                label="PIN"
                outlined
                color="accent"
                clearable
                class="phone-number__form-pin"
                :error-messages="validationMessage($v.pin)"
                @input="$v.pin.$touch()"
                @blur="$v.pin.$touch()"
            />
            <v-btn
                :disabled="verifyCodeBtnDisabled"
                color="accent"
                height="56px"
                class="phone-number__form-btn"
                type="submit"
                :loading="isLoading"
            >
                submit
            </v-btn>
            <v-btn
                :outlined="true"
                color="accent"
                height="56px"
                class="phone-number__form-btn"
                @click="resetCode"
            >
                Reset
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import { validationMessage } from 'vuelidate-messages'

import { validationRules } from '~/validation/phone/RulesPin'
import { formMessages } from '~/validation/phone/Messages'

export default {
    props: {
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            pin: ''
        }
    },
    computed: {
        verifyCodeBtnDisabled() {
            return this.$v.$invalid
        }
    },
    validations: {
        ...validationRules
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        verifyCode() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }
            this.varificationLoading = true
            this.$emit('verifyPin', {
                pin: this.pin
            })
        },
        validate() {
            this.$v.$touch()
        },
        resetCode() {
            this.$emit('resetCode')
        }
    }
}
</script>

<style>

</style>
