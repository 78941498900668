import {
    required, sameAs
} from 'vuelidate/lib/validators';
import passwordRules from '../password/Rules.js'

export const validationRules = {
    password: { ...passwordRules },
    password2: {
        required,
        sameAs: sameAs('password')
    }
}
