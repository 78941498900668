<template>
    <v-dialog
        v-model="showModal"
        max-width="600px"
        @click:outside="onCloseModal"
    >
        <v-card>
            <v-card-title class="text-subtitle-1 py-5 px-5 modal--border d-flex justify-space-between align-center">
                <span>
                    <span>{{ title }}</span>
                </span>
                <v-hover
                    v-slot="{ hover }"
                    style="cursor: pointer;"
                >
                    <v-icon
                        :color="hover ? '#000' : 'gray'"
                        @click="onCloseModal"
                    >
                        mdi-close
                    </v-icon>
                </v-hover>
            </v-card-title>
            <v-card-text class="pt-5 pb-0 px-5">
                <ul
                    v-if="isObjectpayableData"
                    class="pl-0"
                >
                    <li
                        v-for="(value, key) in JSON.parse(payableData)"
                        :key="key"
                    >
                        {{ key }}: <span class="font-weight-bold">{{ value }}</span>
                    </li>
                </ul>
                <span v-else>{{ payableData }}</span>
            </v-card-text>
            <v-card-actions
                class="py-5 px-5"
            >
                <v-btn
                    class="py-4 px-3"
                    height="max-content"
                    @click="onCloseModal"
                >
                    cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        payableData: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            showModal: true
        }
    },
    computed: {
        isObjectpayableData() {
            try {
                return typeof JSON.parse(this.payableData)
            } catch {
                return false
            }
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        }
    }
}
</script>
