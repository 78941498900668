export const ROLES_TABLE_HEADER = [
    {
        text: 'ID',
        value: 'id',
        sortable: false
    },
    {
        text: 'Name',
        value: 'name',
        sortable: false
    },
    {
        text: 'Permissions',
        value: 'permissions',
        sortable: false
    },
    {
        text: 'Created',
        value: 'created_at',
        sortable: false
    },
    {
        text: '',
        class: 'action_block',
        value: 'actions',
        sortable: false
    }
]

export const ROLES_ITEM_TOOLBARS = [
    {
        name: 'delete',
        text: 'Delete',
        modal: {
            name: 'ModalAlert',
            title: 'Remove the role',
            description: 'Do you really want to remove the role?'
        },
        icon: 'mdi-trash-can-outline'
    },
    {
        name: 'edit',
        text: 'Edit',
        modal: {
            name: 'ModalPermission',
            title: 'Edit the role'
        },
        icon: 'mdi-pencil'
    }
]
