<template>
    <div>
        <div
            v-for="role in allRoles"
            :key="role.id"
        >
            <v-checkbox
                :input-value="getRoleValue(role.id)"
                value
                hide-details
                class="mt-0 mb-2"
                :label="role.name"
                @change="value => update(value, role.name)"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'RolesCheckboxs',
    props: {
        allRoles: {
            type: Array,
            default: () => ([])
        },
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        getRoleValue(id) {
            return !!this.item.roles?.find((p) => p.id === id)
        },
        update(val, name) {
            const { id, roles } = this.item
            const names = roles.map((p) => p.name)
            if (val) {
                this.$emit('update', {
                    admin_id: id,
                    role: name
                })
                return
            }
            this.$emit('update', {
                admin_id: id,
                roles: names.filter((item) => item !== name)
            })
        }
    }
}
</script>
