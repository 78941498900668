<template>
    <v-form @submit.prevent="submit">
        <v-row>
            <v-col cols="6">
                <v-text-field
                    v-model="firstname"
                    label="First name"
                    outlined
                    color="accent"
                    prepend-inner-icon="mdi-account-circle"
                    clearable
                    :error-messages="validationMessage($v.firstname)"
                    @input="$v.firstname.$touch()"
                    @blur="$v.firstname.$touch()"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="lastname"
                    label="Last name"
                    outlined
                    color="accent"
                    prepend-inner-icon="mdi-account-circle"
                    clearable
                    :error-messages="validationMessage($v.lastname)"
                    @input="$v.lastname.$touch()"
                    @blur="$v.lastname.$touch()"
                />
            </v-col>
        </v-row>
        <v-text-field
            v-model="email"
            label="Email"
            :readonly="readonly"
            outlined
            color="accent"
            prepend-inner-icon="mdi-email"
            clearable
            :error-messages="validationMessage($v.email)"
            @focus="readonly=false"
            @keydown="spacesNotAllowed"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
        />
        <div>
            <div class="password-tooltip">
                <password-tooltip
                    :password="password"
                    :show="passwordTooltip"
                />
            </div>
            <v-text-field
                v-model="password"
                label="Password"
                :readonly="readonly"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                outlined
                color="accent"
                prepend-inner-icon="mdi-lock"
                :error-messages="validationMessage($v.password)"
                @click:append="showPassword = !showPassword"
                @focus="passwordFocus()"
                @input="$v.password.$touch()"
                @blur="passwordBlur()"
            />
        </div>
        <phone-verification
            ref="phone-verification"
            @phoneVerified="onPhoneVerivied"
        />
        <p>
            Back to
            <router-link
                :to="{name: 'login'}"
                class="accent--text"
            >
                Login
            </router-link>
            or
            <router-link
                :to="{name: 'reset-password'}"
                class="accent--text"
            >
                Forgot your password?
            </router-link>
        </p>
        <div class="mt-8">
            <v-btn
                color="accent"
                class="py-4"
                min-width="100%"
                height="max-content"
                type="submit"
            >
                register
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import { validationMessage } from 'vuelidate-messages'
import { eventBus } from '@/helpers/event-bus/'

import { validationRules } from '~/validation/register/Rules'
import { formMessages } from '~/validation/register/Messages'

import PhoneVerification from '@/components/shared/PhoneVerification/PhoneVerification'
import PasswordTooltip from '@/components/shared/Helpers/PasswordTooltip'

export default {
    components: {
        PhoneVerification,
        PasswordTooltip
    },
    data() {
        return {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            showPassword: false,
            passwordTooltip: false,
            phone: null,
            readonly: true
        }
    },
    validations: {
        ...validationRules
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        onPhoneVerivied(data) {
            this.phone = data
        },
        submit() {
            this.$v.$touch()
            this.$refs['phone-verification'].validate()
            if (!this.$refs['phone-verification'].isComplete) {
                eventBus.$emit('showSnackBar', 'Please compele phone form', 'error')
            }
            if (!this.$v.$invalid && this.phone) {
                this.$emit('registerSubmit', {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    password: this.password,
                    phone: this.phone.phone,
                    phone_code: this.phone.phone_code,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                })
            }
        },
        spacesNotAllowed(e) {
            if (e.code === 'Space') {
                e.preventDefault()
            }
        },
        passwordFocus() {
            this.readonly = false
            this.passwordTooltip = true
        },
        passwordBlur() {
            this.$v.password.$touch()
            this.passwordTooltip = false
        }
    }
}
</script>

<style lang="scss">
    .password-tooltip {
        position: relative;
        left: 50%;
    }
</style>
