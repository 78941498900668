export const PAYMENTS_TABLE_HEADER = [
    {
        text: 'Date',
        sortable: true,
        value: 'created_at',
        width: '150px'
    },
    {
        text: 'Writer',
        sortable: true,
        value: 'sw_id',
        width: '100px'
    },
    {
        text: 'Name',
        sortable: true,
        width: ''
    },
    {
        text: 'Pay Method',
        sortable: false,
        value: 'pay_method',
        width: '150px'
    },
    {
        text: 'Payment ID',
        sortable: true,
        value: 'paymentid',
        width: '100px'
    },
    {
        text: 'Receipt',
        sortable: false,
        value: 'receipt',
        width: '75px'
    },
    {
        text: 'Status',
        sortable: true,
        value: 'status',
        width: '100px'
    },
    {
        text: 'Writer status',
        sortable: true,
        value: 'sw_status',
        width: '100px'
    },
    {
        text: 'Amount',
        sortable: true,
        value: 'amount',
        align: 'right',
        width: '75px'
    }
]
