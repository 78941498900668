export const getPayMethodDetails = (item) => {
    const result = Object.entries(item).reduce((acc, curr) => {
        if (typeof curr[1] === 'object' && curr[1] !== null) {
            acc = Object.entries(curr[1]).reduce((acc2, curr2) => {
                if (typeof curr2[1] === 'object' && curr2[1] !== null) {
                    acc2 = [
                        ...acc2,
                        ...Object.entries(curr2[1]).reduce((acc3, curr3) => {
                            const title = curr3[0].replace(/_/g, ' ')
                            acc3.push(`${title}: ${curr3[1]}`)
                            return acc3
                        }, [])]
                } else {
                    const title = curr2[0].replace(/_/g, ' ')
                    acc2.push(`${title}: ${curr2[1]}`)
                }
                return acc2
            }, [])
        } else {
            const title = curr[0].replace(/_/g, ' ')
            acc.push(`${title}: ${curr[1]}`)
        }
        return acc
    }, [])
    return result;
}
