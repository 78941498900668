<template>
    <modal
        :show-modal="showModal"
        :max-width="1135"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Process payout request</span>
        </template>
        <template #body>
            <v-form>
                <div
                    class="d-flex"
                    style="width: 50%"
                >
                    <v-text-field
                        class="mr-5"
                        label="Date"
                        outlined
                    />
                    <v-text-field
                        class="mr-5"
                        label="Bank fee"
                        outlined
                    />
                    <v-text-field
                        class="mr-5"
                        label="Writer charge"
                        outlined
                    />
                    <v-text-field
                        class="mr-5"
                        label="Receipt"
                        outlined
                    />
                </div>
                <v-divider />
                <div
                    v-for="(_, rowIndex) in rowsCount"
                    :key="rowIndex"
                    class="d-flex mt-2"
                >
                    <v-text-field
                        v-for="(field, index) in $options.rowFields"
                        :key="index"
                        :label="field.name"
                        class="mr-5"
                        outlined
                        hide-details
                    />
                    <v-icon
                        large
                        class="pointer"
                        @click="rowsCount--"
                    >
                        mdi-trash-can-outline
                    </v-icon>
                </div>
                <v-btn
                    text
                    height="56px"
                    class="mt-7"
                    @click="rowsCount++"
                >
                    <v-icon
                        large
                    >
                        mdi-plus
                    </v-icon>
                    Add field
                </v-btn>
                <v-divider />
            </v-form>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    rowFields: [
        { name: 'Debit account:' },
        { name: 'Title' },
        { name: 'Reference ID:' },
        { name: 'Order ID:' },
        { name: 'Country' },
        { name: 'Currency' },
        { name: 'Debit Amount' }
    ],
    components: {
        Modal
    },
    data() {
        return {
            showModal: true,
            rowsCount: 4
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', true)
        }
    }
}
</script>

<style lang="scss" scoped>
    .pointer {
        cursor: pointer;
    }
</style>
