import { TOOLBARS } from './admin-toolbars'

export const ADMINS_TABLE_HEADER = [
    {
        text: 'General',
        value: 'general',
        sortable: false
    },
    {
        text: 'Info',
        value: 'info',
        sortable: false
    },
    {
        text: 'Roles',
        value: 'roles',
        sortable: false
    },
    {
        text: 'Permissions',
        value: 'permissions',
        sortable: false
    },
    {
        text: 'Status',
        value: 'is_active',
        sortable: false
    },
    {
        text: '',
        value: '',
        sortable: false
    }
]

export const ADMINS_TOOLBARS = TOOLBARS
