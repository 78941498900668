<template>
    <div class="wrapper mt-5 clients-page actions-table">
        <v-container
            fluid
            class="white rounded pa-0"
        >
            <the-main-filter
                :search-fields="search_fields"
                :search-values="searchOptions"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                >
                    <v-btn
                        color="success"
                        class="mr-3"
                        @click.stop="createRole"
                    >
                        Create
                    </v-btn>
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'permissions'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Permissions
                        </router-link>
                    </v-hover>
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'admins'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Admins
                        </router-link>
                    </v-hover>
                </template>
            </the-main-filter>

            <v-data-table
                :headers="tableHeader"
                :items="getterRolesList"
                item-key="id"
                :server-items-length="getterTotal"
                :loading="loading"
                hide-default-footer
                calculate-widths
                class="mt-10"
            >
                <template v-slot:body="{ items }">
                    <table-skeleton
                        v-if="loading && (items && items.length === 0)"
                        :per-page="searchOptions.per_page"
                        :column-count="tableHeaderLength"
                    />
                    <table-loader
                        v-if="loading"
                    />
                    <tbody>
                        <tr
                            v-for="item in items"
                            :key="item.id"
                            class="hovered-row"
                            :class="selectRow(item.id)"
                        >
                            <!-- ID -->
                            <td>
                                {{ item.id }}
                            </td>
                            <!-- NAME -->
                            <td>
                                {{ item.name }}
                            </td>
                            <!-- PERMISSIONS -->
                            <td>
                                <permissions-checkboxs
                                    :item="item"
                                    @update="updatePermissions"
                                />
                            </td>
                            <!-- CREATED -->
                            <td>
                                {{ item.created_at && $date.humanizeDate(item.created_at) }} ({{ $date.dateFromNow(item.created_at) }})
                            </td>
                            <!-- ACTIONS -->
                            <td>
                                <table-rows-menu
                                    :toolbars="itemToolbars"
                                    :item="item"
                                    class="mt-0"
                                    @menuActivated="onMenuActivated"
                                />
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>

            <div
                v-if="!getterRolesList.length && !loading "
                class="d-flex flex-column align-center w-full mt-5 mb-5"
            >
                <div class="">
                    <img
                        src="@images/svg/no-data.svg"
                        alt=""
                    >
                </div>
                <p class="text-h6">
                    No roles found
                </p>
            </div>

            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                :current-item="currentItem"
                label="Role name"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import TheFilterPreset from '@/components/shared/TableFilters/TheFilterPreset'
import PermissionsCheckboxs from '@/components/Admin/PermissionsCheckboxs'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalPermission from '@/components/shared/Modals/ModalPermission'
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'
import TableLoader from '@/components/shared/Tables/TableLoader.vue';

import {
    ROLES_ITEM_TOOLBARS,
    ROLES_TABLE_HEADER
} from '@/constants/Roles'

import {
    ROLES_LIST_SEARCH
} from '@/constants/Roles/filter'

import {
    ACTION_CREATE_ROLE,
    ACTION_DELETE_ROLE,
    ACTION_GET_ROLES_LIST,
    ACTION_UPDATE_ROLE,
    ACTION_UPDATE_ROLE_PERMISSIONS,
    GET_ALL_PERMISIONS
} from '@/store/modules/roles/action-types';

const {
    mapActions: mapRolesActions
} = createNamespacedHelpers('roles');

export default {
    name: 'Roles',
    components: {
        TheMainFilter,
        TheFilterPreset,
        PermissionsCheckboxs,
        ModalPermission,
        ModalAlert,
        TableRowsMenu,
        TableSkeleton,
        TableLoader
    },
    data() {
        return {
            loading: true,
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: 'name',
                sort_order: 'DESC'
            },
            modal: {},
            currentItem: {}
        }
    },
    computed: {
        ...mapGetters('roles', [
            'getterRolesList',
            'getterLastPage',
            'getterTotal',
            'getterPermissions'
        ]),
        modalComponent() {
            return this.modal?.name || ''
        },
        itemToolbars() {
            return ROLES_ITEM_TOOLBARS
        },
        tableHeader() {
            return ROLES_TABLE_HEADER
        },
        tableHeaderLength() {
            return ROLES_TABLE_HEADER.length
        },
        queryObject() {
            const { query } = this.$route
            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        search_fields() {
            return ROLES_LIST_SEARCH
        }
    },
    metaInfo: {
        title: 'Roles'
    },
    async created() {
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        Promise.all([await this.getRolesList(), await this[GET_ALL_PERMISIONS]()])
    },
    methods: {
        ...mapRolesActions({
            ACTION_GET_ROLES_LIST,
            ACTION_UPDATE_ROLE_PERMISSIONS,
            ACTION_CREATE_ROLE,
            ACTION_DELETE_ROLE,
            ACTION_UPDATE_ROLE,
            GET_ALL_PERMISIONS
        }),
        createRole() {
            this.modal = {
                name: 'ModalPermission',
                title: 'Create a role'
            }
            this.actionName = 'create'
        },
        onMenuActivated(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentId = item.id
            this.currentItem = item
            this.singleAction = true
        },
        selectRow(id) {
            return {
                'table-row--selected-once': this.currentItem?.id === id
            }
        },
        onFilterTable(payload) {
            const newObj = Object.entries(payload).reduce((acc, curr) => {
                acc[curr[0]] = this.searchOptions[curr[0]]
                return acc
            }, {})
            if (JSON.stringify(payload) !== JSON.stringify(newObj)) {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
                this.getRolesList()
            }
        },
        async getRolesList() {
            if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                this.$router.replace({ query: { ...this.searchOptions } })
            }
            try {
                this.loading = true
                await this[ACTION_GET_ROLES_LIST]({ ...this.searchOptions })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async updatePermissions(payload) {
            try {
                await this[ACTION_UPDATE_ROLE_PERMISSIONS](payload)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        async deleteRole() {
            const { id } = this.currentItem;
            await this[ACTION_DELETE_ROLE]({ id })
            eventBus.$emit('showSnackBar', 'Role has been deleted', 'success')
        },
        async createNewRole(name) {
            await this[ACTION_CREATE_ROLE]({
                data: { name },
                query: {
                    ...this.searchOptions
                }
            })
            eventBus.$emit('showSnackBar', 'Role has been created', 'success')
        },
        async editRole(name) {
            await this[ACTION_UPDATE_ROLE]({
                name,
                id: this.currentItem.id
            })
            eventBus.$emit('showSnackBar', 'Role has been edited', 'success')
        },
        async onConfirmModal(name) {
            try {
                switch (this.actionName) {
                case 'delete':
                    await this.deleteRole()
                    break;
                case 'create':
                    await this.createNewRole(name)
                    break;

                case 'edit':
                    await this.editRole(name)
                    break;

                default:
                    break;
                }
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.loader{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 999;
}
</style>
