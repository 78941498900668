import {
    numeric,
    required
    // requiredIf
} from 'vuelidate/lib/validators';

export const validationRules = {
    pin: {
        // required: requiredIf(function () {
        //     return this.started
        // }),
        required,
        numeric
    }
}
