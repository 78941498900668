<template>
    <div class="wrapper mt-5">
        <v-container
            fluid
            class="white rounded "
        >
            <v-row class="mb-5" />

            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                :active-preset="searchOptions.preset"
                :presets="getterPayoutsPresets"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                    slot-scope="{ events: { updateShortcats } }"
                >
                    <the-filter-preset
                        v-if="!getterPayoutsPresetsLoading"
                        :active-preset="searchOptions.preset"
                        :presets="getterPayoutsPresets"
                        @update:shortcat="updateShortcats"
                    />
                </template>
            </the-main-filter>

            <the-payroll-table
                class="mt-6"
                :search-options="searchOptions"
                :sort-desc="sortDesc"
                :payoneer-balance="payoneerBalance"
                :loading-payoneer-balance="loadingPayoneerBalance"
                @updateOptions="updateOptions"
                @payoutProceed="onPayoutProceed"
                @pendingProceed="onPendingProceed"
                @ShowPayrollDetails="onShowPayrollDetails"
                @ShowPayrollNote="onShowPayrollNote"
                @openReceipt="openReceipt"
                @actionRow="onActionRow"
            />
            <!-- NOTES -->
            <payroll-notes-viewer
                v-if="showNotes"
                :notes="payroll"
                @closeNotes="closePayrollNote"
            />

            <!-- payroll-viewer -->
            <payroll-viewer
                v-if="showPayroll"
                :payroll="payroll"
                @closeDetails="closePayrollDetails"
            />

            <pagination-with-perpage
                :list-lenght="getterPayoutsTotal"
                :last-page="getterPayoutsLastPage"
                :search-options="{ page: searchOptions.page, per_page: searchOptions.per_page }"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onUpdatePerpage"
            />

            <toolbar-menu
                v-if="selected.length"
                :toolbars="toolbars"
                @toolbarActivated="onToolbarActivated"
            />

            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                :input-label="modal.inputLabel"
                :payable-data="modal.payableData"
                :payoutid="modal.payoutid"
                :loading="loadingActions"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import ThePayrollTable from '@/components/PayrollTable/ThePayrollTable.vue';
import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'
import TheFilterPreset from '@/components/shared/TableFilters/TheFilterPreset'
import PayrollViewer from '@/components/SidePanels/PayrollViewer.vue'
import PayrollNotesViewer from '@/components/SidePanels/PayrollNotesViewer'

import Helper from '@/helpers/functions'

import {
    searchFieldsHelper
} from '@/services/searchFieldsHelpers'

import {
    ACTION_GET_CREATE_PAYROLL_NOTE,
    ACTION_GET_WRITERS_PAYMENTS_LIST,
    ACTION_GET_WRITERS_PAYMENTS_TOTALS,
    ACTION_GET_PAYOUTS_PRESETS_WRITER,
    ACTION_GET_PAYABLE,
    ACTION_CREATE_BATCH,
    ACTION_PAY_PAYONEER,
    ACTION_PAY_WIRE,
    ACTION_PAY_MPESA,
    GET_BALANCE
} from '@/store/modules/writers/action-types';

import ModalReceipt from '@/components/shared/Modals/ModalReceipt.vue';
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalEdit from '@/components/shared/Modals/ModalEditPayroll'
import ModalPayroll from '@/components/shared/Modals/ModalProcessPayroll '
import ModalProceedRequest from '@/components/shared/Modals/ModalProceedRequest'
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';

const {
    mapActions: mapPayrollActions
} = createNamespacedHelpers('writers');

export default {
    name: 'Payroll',
    components: {
        TheMainFilter,
        TheFilterPreset,
        ThePayrollTable,
        // Rating,
        // General,
        // Amount,
        // Method,
        // StatusColored,
        // Batch,
        ToolbarMenu,
        ModalAlert,
        ModalEdit,
        ModalPayroll,
        ModalTextarea,
        ModalProceedRequest,
        PaginationWithPerpage,
        PayrollViewer,
        ModalReceipt,
        PayrollNotesViewer
    },
    data() {
        return {
            showPanel: false,
            openedModal: '',
            modal: {},
            search_fields: null,
            toolbars: [
                {
                    name: 'edit',
                    text: 'Edit',
                    modal: {
                        name: 'ModalEdit'
                    },
                    icon: 'mdi-pencil'
                },
                {
                    name: 'process',
                    text: 'Process payment',
                    modal: {
                        name: 'ModalPayroll'
                    },
                    icon: 'mdi-cog'
                },
                {
                    name: 'delete',
                    text: 'Delete',
                    modal: {
                        name: 'ModalAlert'
                    },
                    icon: 'mdi-trash-can-outline'
                }
            ],
            showPayroll: false,
            showNotes: false,
            selected: [],
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            isFirstLoad: true,
            actionName: '',
            processedPaymentId: null,
            processedPaymentType: null,
            loadingActions: false,
            payroll: null,
            loadingPayoneerBalance: false,
            payoneerBalance: null
        }
    },
    metaInfo: {
        title: 'Payroll'
    },
    computed: {
        ...mapGetters('writers', [
            'getterPayoutsTotal',
            'getterPayoutsLastPage',
            'getterPayoutsPresets',
            'getterPayoutsPresetsLoading'
        ]),
        modalComponent() {
            return this.modal.name || ''
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        sortDesc() {
            return this.sort.sort_order === 'DESC'
        }
    },
    watch: {
        showPanel(val) {
            Helper.lockHtml(val)
        }
    },
    async created() {
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        const requests = [this.get_search_fields()]
        // const requests = []
        // if (!this.isFirstLoad) {
        // requests.push(this.getPayouts())
        // }
        await Promise.allSettled(requests)
        // await Promise.allSettled([await this.getListPayroll(), await this.get_search_fields()])
        await this.getBalancePayoneer()
    },
    methods: {
        async getBalancePayoneer() {
            try {
                this.loadingPayoneerBalance = true
                this.payoneerBalance = await this[GET_BALANCE]()
            } catch (error) {
                eventBus.$emit('showSnackBar', `Get balance payoneer: ${error}`, 'error')
            } finally {
                this.loadingPayoneerBalance = false
            }
        },
        onShowPayrollDetails(item) {
            this.payroll = item
            this.showPayroll = true
            this.showPanel = true
        },
        closePayrollDetails() {
            this.showPayroll = false
            this.showPanel = false
        },
        onShowPayrollNote(item) {
            this.payroll = item.notes
            this.showNotes = true
            this.showPanel = true
        },
        closePayrollNote() {
            this.showNotes = false
            this.showPanel = false
        },
        async get_search_fields() {
            this.search_fields = await searchFieldsHelper.getSearchField('payroll')
        },
        ...mapPayrollActions({
            ACTION_GET_WRITERS_PAYMENTS_LIST,
            ACTION_GET_WRITERS_PAYMENTS_TOTALS,
            ACTION_GET_PAYOUTS_PRESETS_WRITER,
            ACTION_GET_PAYABLE,
            ACTION_CREATE_BATCH,
            ACTION_PAY_PAYONEER,
            ACTION_PAY_WIRE,
            ACTION_PAY_MPESA,
            GET_BALANCE,
            ACTION_GET_CREATE_PAYROLL_NOTE
        }),
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getPayouts(false)
        },
        async getPayouts(with_total = true) {
            try {
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                const requests = [
                    this[ACTION_GET_WRITERS_PAYMENTS_LIST]({ ...this.searchOptions, mode: 'list' }), this[ACTION_GET_PAYOUTS_PRESETS_WRITER]()
                ]
                if (with_total) {
                    requests.push(
                        this[ACTION_GET_WRITERS_PAYMENTS_TOTALS]({ ...this.searchOptions, mode: 'count' })
                    )
                }
                await Promise.all(requests)
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onUpdatePerpage(payload) {
            this.searchOptions.page = payload.page
            this.searchOptions.per_page = payload.per_page
            await this.getPayouts()
        },
        async updateOptions(e) {
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'created_at'
                this.sort.sort_order = e.sortDesc[0] ? 'DESC' : 'ASC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort
                }
                await this.getPayouts()
            }
        },
        onToolbarActivated({ modal }) {
            if (modal) {
                this.modal = { ...modal }
            }
        },
        onCloseModal() {
            this.modal = {}
            this.actionName = ''
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'ProceedRequest':
                    await this.createBatch(payload)
                    break
                case 'payProceed':
                    await this.payProceed(payload)
                    break
                case 'notes':
                    await this.createNote(payload)
                    break
                default:
                    return
                }
                // eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async createNote(payload) {
            try {
                await this[ACTION_GET_CREATE_PAYROLL_NOTE]({
                    payout_id: this.currentItem.paymentid,
                    message: payload.details
                })
                await this.getPayouts()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onFilterTable(payload) {
            if (this.isFirstLoad) {
                this.searchOptions = { ...this.searchOptions, ...payload }
            } else {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            }
            await this.getPayouts()
        },
        async onPayoutProceed(payoutid) {
            try {
                const data = await this[ACTION_GET_PAYABLE](payoutid)
                this.actionName = 'ProceedRequest'
                this.modal = {
                    name: 'ModalProceedRequest',
                    payableData: data,
                    payoutid
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onPendingProceed(payoutid, type, message) {
            try {
                this.actionName = 'payProceed'
                this.processedPaymentId = payoutid
                this.processedPaymentType = type
                this.message = message
                this.modal = {
                    name: 'ModalTextarea',
                    title: 'Continue payment',
                    description: 'Are you sure you want to pay for this request?'
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async createBatch({ orders, credits, payoutid }) {
            const requestData = {
                payout_id: payoutid,
                orders: [...orders.map(({ orderid }) => (orderid))],
                credits: [...credits.map(({ id }) => (id))]
            }
            try {
                this.loadingActions = true
                await this[ACTION_CREATE_BATCH](requestData)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingActions = false
            }
        },
        async payProceed(message) {
            switch (this.processedPaymentType) {
            case 'payoneer':
                await this.payPayoneer(message)
                break
            case 'wire':
                await this.payWire(message)
                break
            case 'mpesa':
                await this.payMpesa(message)
                break
            default:
            }
        },
        async payPayoneer(message) {
            try {
                this.loadingActions = true
                await this[ACTION_PAY_PAYONEER]({ payout_id: this.processedPaymentId, message: message.details })
                this.processedPaymentId = null
                eventBus.$emit('showSnackBar', 'Payment was successful', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingActions = false
            }
        },
        async payWire(message) {
            try {
                this.loadingActions = true
                await this[ACTION_PAY_WIRE]({ payout_id: this.processedPaymentId, message: message.details })
                this.processedPaymentId = null
                eventBus.$emit('showSnackBar', 'Payment was successful', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingActions = false
            }
        },
        async payMpesa(message) {
            try {
                this.loadingActions = true
                await this[ACTION_PAY_MPESA]({ payout_id: this.processedPaymentId, message: message.details })
                this.processedPaymentId = null
                eventBus.$emit('showSnackBar', 'Payment was successful', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingActions = false
            }
        },
        openReceipt(receiptData) {
            this.modal = {
                name: 'ModalReceipt',
                title: 'Receipt',
                payableData: receiptData
            }
        },
        onActionRow(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        }
    }
}
</script>
