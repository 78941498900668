<template>
    <div>
        <div
            v-for="permission in showingItems"
            :key="permission.id"
            class="mb-3"
        >
            <v-chip
                color="#EFF5FC"
                text-color="#1976d2"
            >
                {{ permission.name }}
            </v-chip>
        </div>
        <div
            v-if="permissions.length > 3"
            class="mb-3"
        >
            <v-chip
                style="cursor: pointer"
                color="#EFF5FC"
                text-color="#1976d2"
                @click="openModal"
            >
                ...
            </v-chip>
        </div>
        <component
            :is="modalComponent"
            :permissions="permissions"
            @closeModal="onCloseModal"
        />
    </div>
</template>

<script>
import PermissionsModal from '@/components/shared/Modals/PermissionsModal'

export default {
    name: 'PermissionsList',
    components: { PermissionsModal },
    props: {
        permissions: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            modal: {}
        }
    },
    computed: {
        showingItems() {
            return this.permissions?.slice(0, 3) || []
        },
        modalComponent() {
            return this.modal.name || ''
        }
    },
    methods: {
        openModal() {
            this.modal = {
                name: 'PermissionsModal'
            }
        },
        onCloseModal() {
            this.modal = {}
        }
    }
}
</script>
