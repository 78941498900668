import {
    email,
    maxLength,
    minLength,
    required
} from 'vuelidate/lib/validators';
import { checkEmailExists } from '@/services/validationsHelper';
import passwordRules from '../password/Rules.js'

export const validationRules = {
    firstname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
        onlyLattersValidate(value) {
            return /^[a-zA-Z]*$/g.test(value)
        }

    },
    lastname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
        onlyLattersValidate(value) {
            return /^[a-zA-Z]*$/g.test(value)
        }

    },
    email: {
        required,
        email,
        async emailExists(value) {
            // ignore this validator, if any of the previous fail
            if (!this.$v.email.required || !this.$v.email.email) { return true; }

            try {
                await checkEmailExists(value)
                return true
            } catch (error) {
                return false
            }
        }
    },
    password: { ...passwordRules }
}
