import { TOOLBARS } from './payroll-toolbars'

export const PAYROLL_TOOLBARS = TOOLBARS

export const PAYROLL_TABLE_HEADER = [
    {
        text: 'General',
        sortable: false,
        value: 'name'
    },
    {
        text: 'Writer',
        sortable: false,
        value: 'writer'
    },
    {
        text: 'Rating',
        sortable: false,
        value: 'rating'
    },
    {
        text: 'Set method',
        sortable: false,
        value: 'method'
    },
    {
        text: 'Amount',
        sortable: false,
        value: 'amount'
    },
    {
        text: 'Batch',
        sortable: false,
        value: 'batch'
    },
    {
        text: 'Status',
        sortable: false,
        value: 'status'
    },
    {
        text: 'Writer status',
        sortable: false,
        value: 'writer_status'
    }
]
