<template>
    <div class="phone-number">
        <phone-verification-number
            v-if="numberVerified === false"
            ref="verification-number"
            :is-loading="verificationLoading"
            @onVerifyNumber="onVerifyNumber"
        />
        <phone-verification-code
            v-if="numberVerified && codeVerified === false"
            ref="verification-code"
            :is-loading="verificationLoadingCode"
            @verifyPin="onVerifyPin"
            @resetCode="onResetCode"
        />
        <phone-verification-complete
            v-if="codeVerified"
            :phone="phone"
        />
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import Api from '~/helpers/api/index.js'

import PhoneVerificationNumber from './PhoneVerificationNumber'
import PhoneVerificationCode from './PhoneVerificationCode'
import PhoneVerificationComplete from './PhoneVerificationComplete'

export default {
    name: 'PhoneNumber',
    components: {
        PhoneVerificationNumber,
        PhoneVerificationCode,
        PhoneVerificationComplete
    },
    data() {
        return {
            verificationLoading: false,
            verificationLoadingCode: false,
            numberVerified: false,
            codeVerified: false,
            phone: {}
        }
    },
    computed: {
        isComplete() {
            return this.codeVerified
        }
    },
    methods: {
        onVerifyNumber(phone) {
            this.numberVerified = true
            this.phone = phone
        },
        async onVerifyPin(data) {
            try {
                await Api.post('/api/phone-verification/check-pin', { ...this.phone, ...data })
                this.codeVerified = true
                this.$emit('phoneVerified', this.phone)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.verificationLoading = false
            }
        },
        onResetCode() {
            this.numberVerified = false
        },
        validate() {
            if (this.numberVerified === false) {
                const validateNumber = this.$refs['verification-number']
                validateNumber.validate()
                return
            }
            if (this.numberVerified && this.codeVerified === false) {
                const validateCode = this.$refs['verification-code']
                validateCode.validate()
            }
        }
    }
}
</script>

<style lang="scss">
    .phone-number{
        &__form{
            display: flex;
            &-code{
                min-width: 120px;
                width: 120px;
                fieldset{
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
                .v-select__selections{
                    flex-wrap: nowrap !important;
                }
            }
            &-number{
                fieldset{
                    margin-left: -1px;
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                }
            }
            &-btn{
                margin-left: 15px;
            }
        }
        &__code{
            display: flex;
        }
        &__verified{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #e7ffe4;
            padding: 10px 20px 10px 20px;
            position: relative;
            overflow: hidden;
            border-radius: 4px;
            &:before{
                content: '';
                width: 4px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: #59c74d;
            }
        }
    }
</style>
