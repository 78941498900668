<template>
    <div class="wrapper  px-5 py-5">
        <v-container
            fluid
            class="white rounded"
        >
            <the-main-filter
                :search-fields="search_fields"
                :search-values="searchOptions"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                >
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'permissions'}"
                            class="text-decoration-none font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Permissions
                        </router-link>
                    </v-hover>
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'roles'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Roles
                        </router-link>
                    </v-hover>
                </template>
            </the-main-filter>

            <v-data-table
                :headers="tableHeader"
                :items="getterAdminsList"
                item-key="id"
                :server-items-length="getterTotal"
                :loading="loading"
                hide-default-footer
                calculate-widths
                class="mt-10"
                @sortTable="onSortTable"
            >
                <template v-slot:body="{ items }">
                    <table-skeleton
                        v-if="loading && (items && items.length === 0)"
                        :per-page="searchOptions.per_page"
                        :column-count="tableHeaderLength"
                    />
                    <table-loader
                        v-if="loading"
                    />
                    <tbody>
                        <tr
                            v-for="item in items"
                            :key="item.id"
                            class="hovered-row"
                            :class="selectRow(item.id)"
                        >
                            <!-- GENERAL -->
                            <td>
                                <div class="">
                                    <div class="d-flex align-center">
                                        <avatar-rolles
                                            :access-status="'Admin'"
                                            :firstname="item.firstname"
                                            :lastname="item.lastname"
                                            :avatar-url="item.avatar_url"
                                            :avatar-fallback="item.avatar_fallback"
                                            avatar-size="45"
                                            class="mr-4"
                                        />
                                        <div class="">
                                            <p
                                                class="mb-0 font-weight-bold text-decoration-none black--text"
                                            >
                                                {{ nameCapitalized(item.firstname) }} {{ nameCapitalized(item.lastname) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <!-- INFO -->
                            <td>
                                <div class="">
                                    <p class="mb-2">
                                        <span class="grey--text">ID:</span>
                                        {{ item.id }}
                                    </p>
                                    <p class="mb-2">
                                        <span class="grey--text"> Phone:</span>
                                        +{{ item.phone_code }}{{ item.phone }}
                                    </p>
                                    <p class="mb-2">
                                        <span class="grey--text"> Email:</span>
                                        {{ item.email }}
                                    </p>
                                </div>
                            </td>
                            <!-- Roles -->
                            <td>
                                <div class="d-flex">
                                    <roles-checkboxs
                                        :item="item"
                                        :all-roles="getterRolesList"
                                        @update="updateRoles"
                                    />
                                </div>
                            </td>
                            <!-- Permissions -->
                            <td>
                                <permissions-list
                                    v-if="getPermissions(item).length"
                                    :permissions="getPermissions(item)"
                                />
                                <span v-else>
                                    —
                                </span>
                            </td>
                            <!-- Status -->
                            <td>
                                <v-icon
                                    v-if="item.is_active"
                                    color="success"
                                >
                                    mdi-lock-open
                                </v-icon>
                                <v-icon
                                    v-else
                                    color="error"
                                >
                                    mdi-lock
                                </v-icon>
                            </td>
                            <!-- ACTIONS -->
                            <td>
                                <table-rows-menu
                                    :toolbars="toolbars"
                                    :item="item"
                                    class="mt-0"
                                    @menuActivated="actionRow"
                                />
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>

            <pagination-with-perpage
                v-if="!loading"
                :list-lenght="getterAdminsList.length"
                :last-page="getterLastPage || 0"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            />

            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import { nameCapitalized } from '@/helpers/capitalized/index'

import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import TableLoader from '@/components/shared/Tables/TableLoader.vue';
import RolesCheckboxs from '@/components/Admin/RolesCheckboxs'
import PermissionsList from '@/components/Admin/PermissionsList'
import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import ModalAvatarUpload from '@/components/shared/Modals/ModalAvatarUpload'

import {
    ADMINS_TABLE_HEADER, ADMINS_TOOLBARS
} from '@/constants/Admins'

import {
    ADMIN_LIST_SEARCH
} from '@/constants/Admins/filter'

import {
    ACTION_ACTIVATE_ADMIN,
    ACTION_CHANGE_AVATAR,
    ACTION_DEACTIVATE_ADMIN,
    ACTION_GET_ADMIN_LIST,
    ACTION_UPDATE_ADMIN_ROLES
} from '@/store/modules/admin/action-types';

import {
    ACTION_GET_ROLES_LIST
} from '@/store/modules/roles/action-types';

import ModalAlert from '@/components/shared/Modals/ModalAlert'
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'

const {
    mapActions: mapAdminsActions
} = createNamespacedHelpers('admins');
const {
    mapActions: mapRolesActions
} = createNamespacedHelpers('roles');
export default {
    components: {
        ToolbarMenu,
        TableLoader,
        TheMainFilter,
        ModalAlert,
        RolesCheckboxs,
        PermissionsList,
        AvatarRolles,
        PaginationWithPerpage,
        TableSkeleton,
        ModalAvatarUpload,
        TableRowsMenu
    },
    data() {
        return {
            loading: true,
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: 'firstname',
                sort_order: 'DESC'
            },
            modal: {},
            currentItem: {},
            actionName: ''
        }
    },
    metaInfo: {
        title: 'Admins'
    },
    computed: {
        ...mapGetters('admins', [
            'getterAdminsList',
            'getterLastPage',
            'getterTotal',
            'getterCurrentPage'
        ]),
        ...mapGetters('roles', [
            'getterRolesList'
        ]),
        tableHeader() {
            return ADMINS_TABLE_HEADER
        },
        tableHeaderLength() {
            return ADMINS_TABLE_HEADER.length + 1
        },
        modalComponent() {
            return this.modal?.name || ''
        },
        toolbars() {
            return ADMINS_TOOLBARS
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        search_fields() {
            return ADMIN_LIST_SEARCH
        }
    },
    async created() {
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        await this[ACTION_GET_ROLES_LIST]({ per_page: 100, page: 1 })
    },
    methods: {
        nameCapitalized,
        ...mapAdminsActions({
            ACTION_GET_ADMIN_LIST,
            ACTION_ACTIVATE_ADMIN,
            ACTION_DEACTIVATE_ADMIN,
            ACTION_UPDATE_ADMIN_ROLES,
            ACTION_CHANGE_AVATAR
        }),
        ...mapRolesActions({
            ACTION_GET_ROLES_LIST
        }),
        getPermissions(item) {
            const permissionsWithRepit = item.roles?.reduce((permissions, role) => [...permissions, ...(role?.permissions || [])], []) || []
            return [...new Map(permissionsWithRepit.map((item) => [item.id, item])).values()]
        },
        selectRow(id) {
            return {
                'table-row--selected-once': this.currentItem?.id === id
            }
        },
        async updateRoles(payload) {
            try {
                await this[ACTION_UPDATE_ADMIN_ROLES](payload);
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        // TABLE LOGIC
        async getAdminList() {
            if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                this.$router.replace({ query: { ...this.searchOptions } })
            }
            try {
                this.loading = true
                await this[ACTION_GET_ADMIN_LIST]({ ...this.searchOptions })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getAdminList()
        },
        async onFilterTable(payload) {
            this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            await this.getAdminList()
        },
        async onSortTable(flag, option) {
            if (flag) {
                this.searchOptions = {
                    ...this.searchOptions,
                    ...option
                }
            } else {
                this.searchOptions = {
                    ...this.searchOptions,
                    ...option,
                    page: 1
                }
            }
            await this.getAdminList()
        },
        // Modal logic
        actionRow(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        async activateAdmin() {
            await this[ACTION_ACTIVATE_ADMIN](this.currentItem.id)
        },
        async deactivateAdmin() {
            await this[ACTION_DEACTIVATE_ADMIN](this.currentItem.id)
        },
        async adminAvatarChage(payload) {
            payload.append('id', this.currentItem.id)
            await this[ACTION_CHANGE_AVATAR](payload)
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'activated':
                    await this.activateAdmin()
                    break;
                case 'change_avatar':
                    await this.adminAvatarChage(payload)
                    break;
                case 'deactivated':
                    await this.deactivateAdmin()
                    break;
                default:
                    break;
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.loader{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 999;
}
</style>
