<template>
    <modal
        :show-modal="showModal"
        :loading="loading"
        :max-width="1135"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Process payout request</span>
        </template>
        <template #body>
            <v-form>
                <v-row>
                    <v-col cols="6">
                        <div
                            v-for="order in payableData.orders"
                            :key="order.orderid"
                            class="d-flex align-center"
                        >
                            <v-checkbox
                                :input-value="getOrderValue(order.orderid)"
                                value
                                hide-details
                                class="mt-0 pb-2"
                                @change="updateOrder(order.orderid)"
                            />
                            <div class="d-flex align-center">
                                <div>
                                    <router-link
                                        :to="{
                                            path: `/orders/${order.orderid}`
                                        }"
                                        target="_blank"
                                        class="text-body-4 black--text text-decoration-none"
                                    >
                                        <p class="mb-0 text-body-1">
                                            {{ order.orderid }}
                                        </p>
                                    </router-link>
                                </div>
                                <p class="mb-0 ml-5 black--text text-body-1 font-weight-bold">
                                    {{ order.sw_total | money }}
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div
                            v-for="credit in payableData.credits"
                            :key="credit.id"
                        >
                            <v-checkbox
                                :input-value="getCreditValue(credit.id)"
                                value
                                hide-details
                                class="mt-0 mb-2"
                                :label="labelForCredit(credit)"
                                @change="updateCredit(credit.id)"
                            />
                        </div>
                    </v-col>
                </v-row>
                <v-divider />
                <div class="d-flex align-center justify-space-between text-subtitle-1 py-4">
                    <div>
                        Total sum for payout:
                        <span
                            :class="{'error--text': Number(totalSum) < 0}"
                            class="black--text"
                        >{{ totalSum | money }}</span>
                    </div>
                    <div>
                        Total orders:
                        <span class="black--text">{{ payableData.orders.length }}</span>
                    </div>
                    <div>
                        Total credits:
                        <span class="black--text">{{ payableData.credits.length }}</span>
                    </div>
                </div>
                <v-divider />
            </v-form>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'
import filtersMixin from '@/mixins/filtersMixin'

export default {
    components: {
        Modal
    },
    mixins: [
        filtersMixin
    ],
    props: {
        payableData: {
            type: Object,
            required: true
        },
        payoutid: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showModal: true,
            selectedOrders: [],
            selectedCredits: []
        }
    },
    computed: {
        totalSum() {
            let total = 0
            this.selectedOrders.forEach(({ sw_total }) => {
                total += +sw_total
            })
            this.selectedCredits.forEach(({ credit, debit }) => {
                if (credit) {
                    total += +credit
                } else {
                    total -= debit
                }
            })
            return total
        }
    },
    created() {
        this.selectedOrders = [...this.payableData.orders]
        this.selectedCredits = [...this.payableData.credits]
    },
    methods: {
        getOrderValue(orderid) {
            return this.selectedOrders.some((order) => order.orderid === orderid)
        },
        getCreditValue(id) {
            return this.selectedCredits.some((credit) => credit.id === id)
        },
        updateOrder(orderid) {
            if (this.selectedOrders.some((order) => order.orderid === orderid)) {
                this.selectedOrders = this.selectedOrders.filter((order) => order.orderid !== orderid)
                return
            }
            const order = this.payableData.orders.find((order) => order.orderid === orderid)
            this.selectedOrders.push(order)
        },
        updateCredit(id) {
            if (this.selectedCredits.some((credit) => credit.id === id)) {
                this.selectedCredits = this.selectedCredits.filter((credit) => credit.id !== id)
                return
            }
            const credit = this.payableData.credits.find((credit) => credit.id === id)
            this.selectedCredits.push(credit)
        },
        // labelForOrder(order) {
        //     return `${order.orderid}, ${order.sw_total}`
        // },
        labelForCredit({ credit, debit }) {
            const text = credit ? `Credit amount: ${credit}` : `Debit amount: -${debit}`
            return text
        },
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', { orders: this.selectedOrders, credits: this.selectedCredits, payoutid: this.payoutid })
        }
    }
}
</script>

<style lang="scss" scoped>
    .pointer {
        cursor: pointer;
    }
</style>
