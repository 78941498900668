<template>
    <div class="phone-number__verified mb-5">
        <span>
            Phone: <b>+{{ phone.phone_code }} {{ phone.phone }}</b>
        </span>
        <span>
            <v-icon color="success">
                mdi-checkbox-marked-circle
            </v-icon>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        phone: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>
