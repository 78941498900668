export const FILES_TABLE_HEAD = [
    {
        text: 'Name',
        value: 'filename',
        sortable: true
    },
    {
        text: 'Type',
        value: 'status',
        sortable: true
    },
    {
        text: 'Owner',
        value: 'owner_type',
        sortable: false
    },
    {
        text: 'Date',
        value: 'created_at',
        sortable: true
    },
    {
        text: 'Size',
        value: 'filesize',
        sortable: true
    },
    {
        text: 'Actions',
        value: 'actions',
        sortable: false
    }
]

export const ALLOWED_TYPES = [
    'application/pdf',
    'image/png',
    'image/jpeg',
    'application/msword',
    '.doc',
    '.docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]
