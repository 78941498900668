<template>
    <auth-form-template>
        <template #header>
            <span class="text-h6">
                Reset password
            </span>
        </template>
        <template #body>
            <v-form @submit.prevent="submit">
                <div>
                    <div class="password-tooltip">
                        <password-tooltip
                            :password="password"
                            :show="passwordTooltip"
                        />
                    </div>
                    <v-text-field
                        v-model="password"
                        :error-messages="validationMessage($v.password)"
                        label="Password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        prepend-inner-icon="mdi-lock"
                        outlined
                        color="accent"
                        clearable
                        @click:append="showPassword = !showPassword"
                        @focus="passwordTooltip = true"
                        @keydown="spacesNotAllowed"
                        @input="$v.password.$touch()"
                        @blur="passwordBlur()"
                    />
                </div>
                <v-text-field
                    v-model="password2"
                    :error-messages="validationMessage($v.password2)"
                    label="Repeat password"
                    :type="showPassword2 ? 'text' : 'password'"
                    :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                    prepend-inner-icon="mdi-lock"
                    outlined
                    color="accent"
                    clearable
                    @click:append="showPassword2 = !showPassword2"
                    @keydown="spacesNotAllowed"
                    @input="$v.password2.$touch()"
                    @blur="$v.password2.$touch()"
                />
                <v-btn
                    color="accent"
                    class="py-4"
                    min-width="100%"
                    height="max-content"
                    type="submit"
                >
                    reset password
                </v-btn>
            </v-form>
        </template>
    </auth-form-template>
</template>

<script>
import { validationMessage } from 'vuelidate-messages'
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'
import AuthFormTemplate from '@/components/Auth/AuthFormTemplate'

import { validationRules } from '~/validation/reset-password/Rules'
import { formMessages } from '~/validation/reset-password/Messages'

import PasswordTooltip from '@/components/shared/Helpers/PasswordTooltip'

export default {
    components: {
        AuthFormTemplate,
        PasswordTooltip
    },
    metaInfo: {
        title: 'Reset password'
    },
    validations: {
        ...validationRules
    },
    data() {
        return {
            password: '',
            password2: '',
            showPassword: false,
            showPassword2: false,
            email: '',
            passwordTooltip: false
        }
    },
    async created() {
        await this.getData()
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                eventBus.$emit('showSnackBar', 'Please fill the form correctly.', 'error')
            } else {
                try {
                    await Api.put('/api/auth/forgot-password/set-new-password', {
                        token: this.$route.params.token,
                        password: this.password,
                        email: this.email
                    })
                    eventBus.$emit('showSnackBar', 'Your password has been changed', 'success')
                    this.$router.push({ name: 'login' })
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error')
                }
            }
        },
        async getData() {
            try {
                const { data } = await Api.post('/api/auth/forgot-password/validate-token', {
                    token: this.$route.params.token
                })
                this.email = data
            } catch (error) {
                this.expired = true
                eventBus.$emit('showSnackBar', 'The link has expired, you need to use the reset password form again.', 'info')
                this.$router.push({ name: 'forgot-password' })
            }
        },
        spacesNotAllowed(e) {
            if (e.code === 'Space') {
                e.preventDefault()
            }
        },
        passwordBlur() {
            this.$v.password.$touch()
            this.passwordTooltip = false
        }
    }
}
</script>
<style lang="scss">
    .password-tooltip {
        position: relative;
        left: 50%;
    }
</style>
