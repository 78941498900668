<template>
    <auth-form-template>
        <template #header>
            <span class="text-h6">
                CRM Register
            </span>
        </template>
        <template #body>
            <the-register-form @registerSubmit="onRegisterSubmit" />
        </template>
    </auth-form-template>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import AuthFormTemplate from '@/components/Auth/AuthFormTemplate'
import TheRegisterForm from '@/components/Auth/TheRegisterForm'
import {
    ACTION_REGISTER
} from '@/store/modules/client/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('client');

export default {
    components: {
        AuthFormTemplate,
        TheRegisterForm
    },
    metaInfo: {
        title: 'Register'
    },
    methods: {
        ...mapAdminActions({
            ACTION_REGISTER
        }),
        onRegisterSubmit(data) {
            this[ACTION_REGISTER](data)
                .then(() => {
                    eventBus.$emit('showSnackBar', 'You have successfully registered, please wait for further instructions.', 'success')
                    this.$router.push({ name: 'login' })
                })
                .catch((err) => {
                    eventBus.$emit('showSnackBar', err, 'error')
                })
        }
    }
}
</script>
