export const PERMISION_LIST_SEARCH = {
    search_by: {
        value: 'search_by',
        cols: 12,
        list: [
            {
                text: 'ID',
                value: 'id'
            },
            {
                text: 'Name',
                value: 'name'
            }
        ]
    }
}
