import { PAYMENTS_TABLE_HEADER } from './writers-payments'
import {
    TOOLBAR_APPLICANT,
    NOTE_TOOLBARS,
    TOOLBARS
} from './writers-toolbar'
import { APPLICANT_TABS } from './writers-tabs'
import { ALLOWED_TYPES, FILES_TABLE_HEAD } from './writers-files'

export const WRITERS_PAYMENTS_TABLE_HEADER = PAYMENTS_TABLE_HEADER
export const WRITERS_TOOLBARS = TOOLBARS
export const WRITERS_NOTE_TOOLBARS = NOTE_TOOLBARS
export const WRITERS_FILES_TABLE_HEAD = FILES_TABLE_HEAD

export const TOOLBARS_APPLICANT = TOOLBAR_APPLICANT
export const APPLICANT_DETAIL_TABS = APPLICANT_TABS
export const ALLOWED_FILE_TYPES = ALLOWED_TYPES

export const WRITERS_TABLE_HEADER = [
    {
        text: 'In-x',
        value: 'index',
        width: '60px',
        align: 'center',
        sortable: false
    },
    {
        text: 'General',
        sortable: true,
        value: 'lastname'
    },
    {
        text: 'Country',
        sortable: true,
        value: 'country'
    },
    {
        text: 'Rating',
        sortable: false,
        value: 'rank'
    },
    {
        text: 'Exams',
        sortable: false,
        value: ''
    },
    {
        text: 'Applied',
        sortable: true,
        value: 'created_at'
    },
    {
        text: 'Online',
        sortable: false,
        value: 'login_pinged_at'
    },
    {
        text: 'Earnings',
        sortable: true,
        value: 'earnings_all'
    },
    {
        text: 'Balance',
        sortable: true,
        value: 'earnings_pending'
    },
    {
        text: 'Reviews',
        sortable: true,
        value: 'rank_all_count'
    },
    {
        text: 'Access',
        sortable: true,
        value: 'accessstatus'
    },
    {
        text: 'Status',
        sortable: true,
        value: 'is_active'
    },
    {
        text: 'Limit',
        sortable: true,
        value: 'projects_limit'
    },
    {
        text: '',
        sortable: false,
        width: '55px'
    }
]

export const APPLICANT_TABLE_HEADER = [
    {
        text: 'In-x',
        value: 'index',
        width: '60px',
        align: 'center',
        sortable: false
    },
    {
        text: 'General',
        sortable: true,
        value: 'lastname'
    },
    {
        text: 'Login / Request',
        sortable: true,
        value: 'last_ts'
    },
    {
        text: 'Applied',
        sortable: true,
        value: 'ts_apply'
    },
    {
        text: 'Status',
        sortable: true,
        value: 'status'
    },
    {
        text: '',
        sortable: false,
        width: '55px'
    }
]

export const TRUST_STATUSES = [
    {
        text: 'Trusted',
        value: 'TRUSTED'
    },
    {
        text: 'New',
        value: 'NEW'
    }
]

export const APPLICANT_STATUSES = [
    {
        text: 'Open',
        value: 'OPEN'
    },
    {
        text: 'Activate',
        value: 'ACTIVE'
    },
    {
        text: 'Close',
        value: 'CLOSED'
    }
]

export const EXAM_TABLE_HEADER = [
    {
        text: 'In-x',
        value: 'index',
        width: '60px',
        align: 'center',
        sortable: false
    },
    {
        text: 'Session ID',
        value: 'session_id',
        sortable: false
    },
    {
        text: 'Created',
        value: 'created_at',
        sortable: true
    },
    {
        text: 'Updated',
        value: 'updated_at',
        sortable: true
    },
    {
        text: 'Right answers',
        value: 'count_correct_answer',
        sortable: false
    },
    {
        text: 'Total answers',
        value: 'answers_total',
        sortable: false
    },
    {
        text: '',
        sortable: false,
        width: '55px'
    }
]
