<template>
    <viewer
        title="Payroll details"
        container-width="80%"
        @closeSidePanel="closeDetails"
    >
        <template #body>
            <div class="my-4">
                <v-data-table
                    :headers="tableHeaderPayroll"
                    :items="payroll.pay_details"
                    item-key="id"
                    class="mt-2 payroll-details-desctop"
                >
                    <template v-slot:body="{ items }">
                        <tbody class="">
                            <tr
                                v-for="(item, index) in items"
                                :key="index"
                                class=""
                            >
                                <td>
                                    <span class="mb-2">
                                        {{ index + 1 }}
                                    </span>
                                </td>
                                <td>
                                    <router-link
                                        :to="{
                                            path: `/orders/${item.order_id}`
                                        }"
                                        class="black--text text-decoration-none order-card_header_link"
                                    >
                                        <div class="">
                                            {{ item.order_id }}
                                        </div>
                                    </router-link>
                                </td>
                                <td>
                                    <span class="mb-2 truncate-text">
                                        {{ item.title }}
                                    </span>
                                </td>
                                <td>
                                    <span class="mb-2">
                                        {{ $date.shortFormat(item.created_at) }}
                                    </span>
                                </td>
                                <td>
                                    <span class="mb-2">
                                        {{ $date.shortFormat(item.due_at) }}
                                    </span>
                                </td>
                                <td>
                                    <span class="mb-2">
                                        {{ $date.shortFormat(item.completed_at ) }}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        class="mb-2 font-weight-bold"
                                        style="font-size: 14px"
                                    >
                                        {{ item.cost | money }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
                <ul class="payroll-details-mobile">
                    <li
                        v-for="(item, index) in payroll.pay_details"
                        :key="index"
                    >
                        <div class="payroll-details-mobile__item">
                            <div class="payroll-details-mobile__item-wrapper">
                                <span class="payroll-details-mobile__item-index">{{ index + 1 }}.</span>
                                <router-link
                                    :to="{
                                        path: `/orders/${item.order_id}`
                                    }"
                                    class="black--text text-decoration-none payroll-details-mobile__item-order"
                                >
                                    <div class="">
                                        {{ item.order_id }}
                                    </div>
                                </router-link>
                                <span class="payroll-details-mobile__item-title truncate-text">{{ item.title }}</span>
                                <information-tooltip>
                                    <div>
                                        <div class="mb-1">
                                            <p class="mb-0 text-body-2">
                                                Created:
                                            </p>
                                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                {{ item.created_at ? $date.fullDate(item.created_at) : '—' }}
                                            </p>
                                        </div>
                                        <div class="mb-1">
                                            <p class="mb-0 text-body-2">
                                                Due:
                                            </p>
                                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                {{ item.due_at ? $date.fullDate(item.due_at) : '—' }}
                                            </p>
                                        </div>
                                        <div class="mb-1">
                                            <p class="mb-0 text-body-2">
                                                Completed:
                                            </p>
                                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                {{ item.completed_at ? $date.fullDate(item.completed_at) : '—' }}
                                            </p>
                                        </div>
                                    </div>
                                </information-tooltip>
                            </div>
                            <span class="payroll-details-mobile__item-money">{{ item.cost | money }}</span>
                        </div>
                    </li>
                </ul>
                <h1
                    class="debit-credit-title"
                    :class="payroll.credits.length ? 'show-debit-credit-title' : 'hidden-debit-credit-title'"
                >
                    Debit / Credit
                </h1>
                <v-data-table
                    :headers="tableHeaderDebitCredit"
                    :items="payroll.credits"
                    item-key="id"
                    calculate-widths
                    class="mt-2 payroll-credit-debit-desctop"
                >
                    <template v-slot:body="{ items }">
                        <tbody class="">
                            <tr
                                v-for="(item, index) in items"
                                :key="index"
                                :class="item.debit ? 'tw-bg-red-200' : 'tw-bg-green-200'"
                            >
                                <td>
                                    <span
                                        v-if="item.debit"
                                        class="mb-2 font-weight-bold"
                                        style="font-size: 14px"
                                    >
                                        {{ - item.debit | money }}
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <span
                                        v-if="item.credit"
                                        class="mb-2 font-weight-bold"
                                        style="font-size: 14px"
                                    >
                                        {{ item.credit | money }}
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <span class="mb-2">
                                        {{ $date.shortFormat(item.created_at) }}
                                    </span>
                                </td>
                                <td>
                                    <span class="mb-2">
                                        {{ item.reference }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
                <ul class="payroll-credit-debit-mobile">
                    <li
                        v-for="(item, index) in payroll.credits"
                        :key="index"
                        class="payroll-credit-debit-mobile__item"
                        :class="item.debit ? 'tw-bg-red-200' : 'tw-bg-green-200'"
                    >
                        <span
                            v-if="item.debit"
                            class="payroll-credit-debit-mobile__item-debit"
                        >
                            {{ - item.debit | money }}
                        </span>
                        <span
                            v-else
                            class="payroll-credit-debit-mobile__item-debit"
                        >-</span>
                        <span
                            v-if="item.credit"
                            class="payroll-credit-debit-mobile__item-credit"
                        >
                            {{ item.credit | money }}
                        </span>
                        <span
                            v-else
                            class="payroll-credit-debit-mobile__item-credit"
                        >-</span>
                        <information-tooltip>
                            <div>
                                <div class="mb-1">
                                    <p class="mb-0 text-body-2">
                                        Created:
                                    </p>
                                    <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                        {{ item.created_at ? $date.fullDate(item.created_at) : '—' }}
                                    </p>
                                </div>
                            </div>
                        </information-tooltip>
                        <span class="payroll-credit-debit-mobile__item-title">{{ item.reference }}</span>
                    </li>
                </ul>
                <div class="flex justify-end align-center my-5">
                    <div class="d-flex align-baseline">
                        <p class="mr-2 mb-0">
                            Ammount:
                        </p>
                        <p
                            class="font-weight-bold mb-0"
                            style="font-size: 20px"
                        >
                            {{ payroll.amount | money }}
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </viewer>
</template>

<script>

import Viewer from '@/components/SidePanels/Viewer'
import filterMixin from '@/mixins/filtersMixin'
import InformationTooltip from '@/components/shared/InformationTooltip.vue';

export default {
    components: {
        InformationTooltip,
        Viewer
    },
    mixins: [
        filterMixin
    ],
    props: {
        payroll: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {
        tableHeaderPayroll() {
            return [
                {
                    text: 'In-x',
                    sortable: false,
                    value: 'index',
                    width: '20px'
                },
                {
                    text: 'Order ID',
                    sortable: false,
                    width: '50px'
                },
                {
                    text: 'Title',
                    sortable: false,
                    width: '50px'
                },
                {
                    text: 'Сreated at',
                    sortable: false,
                    width: '50px'
                },
                {
                    text: 'Due at',
                    sortable: false,
                    width: '50px'
                },
                {
                    text: 'Completed at',
                    sortable: false,
                    width: '50px'
                },
                {
                    text: 'Total',
                    sortable: false,
                    width: '30px'
                }
            ]
        },
        tableHeaderDebitCredit() {
            return [
                {
                    text: 'Debit',
                    sortable: false,
                    width: '30px'
                },
                {
                    text: 'Credit',
                    sortable: false,
                    width: '30px'
                },
                {
                    text: 'Created at',
                    sortable: false,
                    width: '20px'
                },
                {
                    text: 'Reference',
                    sortable: false,
                    value: 'index',
                    width: '20px'
                }
            ]
        }
    },
    methods: {
        closeDetails() {
            this.$emit('closeDetails')
        }
    }
}
</script>

<style lang="scss">
.truncate-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.payroll-details-desctop {
    @media (max-width: 599px) {
        display: none !important;
    }
}

.payroll-details-mobile{
    padding-left: 0 !important;
    display: none !important;
    @media (max-width: 599px) {
        display: block !important;
    }
    &__item{
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        font-size: 11px;
    }
    &__item-wrapper{
        display: flex !important;
        align-items: center;
        gap: 10px;
    }
    &__item-index{
        width: 20px;
    }
    &__item-order{
        //font-size: 12px;
    }
    &__item-title{
        max-width: 100px;
        width: 100px;
    }
    &__item-money {
        font-weight: bold;
    }
}

.debit-credit-title {
    font-size: 1.25rem !important;
    font-weight: 700;
    line-height: 1.5;
    padding: 20px 0;
}

.show-debit-credit-title {
    display: block !important;
}

.hidden-debit-credit-title{
    @media (max-width: 599px) {
        display: none !important;
    }
}

.payroll-credit-debit-desctop{
    @media (max-width: 599px) {
        display: none !important;
    }
}

.payroll-credit-debit-mobile {
    margin: 10px 0;
    padding-left: 0 !important;
    display: none !important;
    @media (max-width: 599px) {
        display: block !important;
    }
    &__item{
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        font-size: 11px;
        border-bottom: white 1px solid;
    }
    &__item-debit {
        width: 50px;
        font-weight: bold;
    }
    &__item-credit {
        width: 50px;
        font-weight: bold;
    }
    &__item-title{
        width: 50%;
    }
}
</style>
