export default {
    filters: {
        money(value) {
            return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || '0.00'
        },
        number(value) {
            return Number(value).toLocaleString('en-US')
        },
        percent(value) {
            return Number(value).toLocaleString('en-US', { style: 'percent' })
        }
    }
}
