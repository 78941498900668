<template>
    <v-form @submit.prevent="submit">
        <p class="mb-10">
            We will send instructions on how to reset your password to your account's e-mail address:
        </p>
        <v-text-field
            v-model="email"
            :error-messages="validationMessage($v.email)"
            label="Email"
            outlined
            color="accent"
            prepend-inner-icon="mdi-email"
            clearable
            @keydown="spacesNotAllowed"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
        />
        <p>
            Forgot your password?
            <router-link
                :to="{name: 'login'}"
                class="accent--text"
            >
                Login
            </router-link>
            or
            <router-link
                :to="{name: 'register'}"
                class="accent--text"
            >
                Register Account
            </router-link>
        </p>
        <div class="mt-8">
            <v-btn
                color="accent"
                class="py-4"
                min-width="100%"
                height="max-content"
                type="submit"
                :loading="loading"
                :disabled="!email.length"
            >
                Reset
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import { validationMessage } from 'vuelidate-messages'

// import { eventBus } from '@/helpers/event-bus/'
import { validationRules } from '~/validation/login/Rules'
import { formMessages } from '~/validation/login/Messages'

export default {
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            email: ''
        }
    },
    validations: {
        ...validationRules
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        submit() {
            this.$emit('resetPassword', { email: this.email })
        },
        spacesNotAllowed(e) {
            if (e.code === 'Space') {
                e.preventDefault()
            }
        }
    }
}
</script>

<style>

</style>
