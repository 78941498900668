<template>
    <auth-form-template>
        <template #header>
            <span class="text-h6">
                Provide e-mail to recover password
            </span>
        </template>
        <template #body>
            <the-reset-password-form
                :loading="loading"
                @resetPassword="onResetPassword"
            />
        </template>
    </auth-form-template>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import AuthFormTemplate from '@/components/Auth/AuthFormTemplate'
import TheResetPasswordForm from '@/components/Auth/TheResetPasswordForm'

import {
    ACTION_RESET_PASSWORD
} from '@/store/modules/client/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('client');

export default {
    components: {
        AuthFormTemplate,
        TheResetPasswordForm
    },
    metaInfo: {
        title: 'Reset password'
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        ...mapAdminActions({
            ACTION_RESET_PASSWORD
        }),
        onResetPassword(payload) {
            this.loading = true
            this[ACTION_RESET_PASSWORD](payload)
                .then(() => {
                    this.$router.push({ name: 'login' })
                    eventBus.$emit('showSnackBar', 'Instructions for restoring access have been sent to your email.', 'success')
                })
                .catch((e) => {
                    eventBus.$emit('showSnackBar', e, 'error')
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>
