<template>
    <v-row>
        <v-col
            v-for="permission in getterPermissions"
            :key="permission.id"
            cols="12"
            class="py-0"
        >
            <v-checkbox
                :input-value="getPermissonValue(permission.id)"
                value
                :label="permission.name"
                class="my-2"
                hide-details
                @change="value => update(value, permission.id)"
            />
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'PermissionsCheckboxs',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('roles', [
            'getterPermissions'
        ])
    },
    methods: {
        getPermissonValue(id) {
            return !!this.item.permissions.find((p) => p.id === id)
        },
        update(val, id) {
            const { permissions } = this.item
            const ids = permissions.map((p) => p.id)
            if (val) {
                this.$emit('update', {
                    id: this.item.id,
                    permissions: [...ids, id]
                })
                return
            }
            this.$emit('update', {
                id: this.item.id,
                permissions: ids.filter((item) => item !== id)
            })
        }
    }
}
</script>
