import {
    required,
    numeric,
    minLength,
    maxLength
} from 'vuelidate/lib/validators';
import { checkPhoneExists } from '@/services/validationsHelper'

export const validationRules = {
    phone_code: {
        required,
        objectStructure(value) {
            const index = this.countries.findIndex((item) => item.phonecode === value.phonecode)
            return index >= 0
        }
    },
    phone_number: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(20),
        async isUniquePhoneNumber() {
            if (!this.phone_code || !this.phone_number) return true
            if (!this.$v.phone_number.required || !this.$v.phone_number.minLength || !this.$v.phone_number.maxLength) return true
            try {
                await checkPhoneExists({
                    phone_code: this.phone_code,
                    phone: this.phone_number
                })
                return true
            } catch (error) {
                return false
            }
        }
    }
}
