<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-text-field
                v-model="name"
                :label="label"
                outlined
                :rules="[rules.required, rules.minLenght, rules.maxLenght]"
            />
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    name: 'ModalPermission',
    components: {
        Modal
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            default: 'Permission name'
        },
        currentItem: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            showModal: true,
            name: '',
            rules: {
                required: (value) => !!value || 'Required.',
                minLenght: (value) => value.length >= 10 || 'Min 10 characters',
                maxLenght: (value) => value.length <= 64 || 'Min 64 characters'
            }
        }
    },
    created() {
        this.name = this.currentItem?.name || ''
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', this.name)
        }
    }
}
</script>

<style>

</style>
