import {
    maxLength,
    minLength,
    required
} from 'vuelidate/lib/validators';

export default {
    required,
    minLength: minLength(7),
    maxLength: maxLength(50)
}
