<template>
    <div>
        <v-tooltip
            v-model="show"
            top
            color="accent"
        >
            <template v-slot:activator="{ on, attrs }">
                <span
                    color="primary"
                    v-bind="attrs"
                />
            </template>
            <ul class="password-tooltip__list">
                <li class="mb-2">
                    <v-icon
                        v-if="password.length >= 8"
                        color="green accent-4"
                    >
                        mdi-check-circle
                    </v-icon>
                    <v-icon
                        v-else
                        color="red lighten-1"
                    >
                        mdi-alert-circle-outline
                    </v-icon>
                    Must be at least 8 characters.
                </li>
                <li class="mb-2">
                    <v-icon
                        v-if="password.length <= 20"
                        color="green accent-4"
                    >
                        mdi-check-circle
                    </v-icon>
                    <v-icon
                        v-else
                        color="red lighten-1"
                    >
                        mdi-alert-circle-outline
                    </v-icon>
                    Must be less than 20 characters.
                </li>
                <li class="mb-2">
                    <v-icon
                        v-if="lowerCasePatternTest(password)"
                        color="green accent-4"
                    >
                        mdi-check-circle
                    </v-icon>
                    <v-icon
                        v-else
                        color="red lighten-1"
                    >
                        mdi-alert-circle-outline
                    </v-icon>
                    Must be at least one lowercase character.
                </li>
                <li class="mb-2">
                    <v-icon
                        v-if="upperCasePatternTest(password)"
                        color="green accent-4"
                    >
                        mdi-check-circle
                    </v-icon>
                    <v-icon
                        v-else
                        color="red lighten-1"
                    >
                        mdi-alert-circle-outline
                    </v-icon>
                    Must be at least one uppercase character.
                </li>
                <li>
                    <v-icon
                        v-if="numberPatternTest(password)"
                        color="green accent-4"
                    >
                        mdi-check-circle
                    </v-icon>
                    <v-icon
                        v-else
                        color="red lighten-1"
                    >
                        mdi-alert-circle-outline
                    </v-icon>
                    Must be at least one number.
                </li>
            </ul>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        password: {
            type: String,
            required: true
        },
        show: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        lowerCasePatternTest(value) {
        // At least one LOWERCASE character:
            const lowerCasePattern = /^(?=.*[a-z]).+$/;
            return lowerCasePattern.test(value)
        },
        upperCasePatternTest(value) {
        // At least one LOWERCASE character:
            const upperCasePattern = /^(?=.*[A-Z]).+$/;
            return upperCasePattern.test(value)
        },
        numberPatternTest(value) {
        // At least one LOWERCASE character:
            const numberPattern = /^(?=.*[\d]).+$/;
            return numberPattern.test(value)
        }
    }
}
</script>
<style lang="scss">
    .password-tooltip__list {
        width: 360px;
        padding: 5px 0 !important;
        list-style: none;
    }
</style>
