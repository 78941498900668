<template>
    <auth-form-template>
        <template #header>
            <span class="text-h6">
                CRM Login
            </span>
        </template>
        <template #body>
            <the-login-form
                :loading="loading"
                :show-otp="showOtp"
                :correct-otp="correctOtp"
                @submit="onSubmit"
                @updateValidateCode="updateValidateCode"
            />
        </template>
    </auth-form-template>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import AuthFormTemplate from '@/components/Auth/AuthFormTemplate'
import TheLoginForm from '@/components/Auth/TheLoginForm'

import {
    ACTION_LOGIN
} from '@/store/modules/client/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('client');

export default {
    components: {
        AuthFormTemplate,
        TheLoginForm
    },
    data() {
        return {
            loading: false,
            showOtp: false,
            correctOtp: true
        }
    },
    metaInfo: {
        title: 'Login'
    },
    methods: {
        ...mapAdminActions({
            ACTION_LOGIN
        }),
        async onSubmit(data) {
            this.loading = true
            await this[ACTION_LOGIN](data)
                .then(() => {
                    // eventBus.$emit('showSnackBar', 'Instructions for restoring access have been sent to your email.', 'success')
                    this.$router.push({ name: 'dashboard' })
                })
                .catch((err) => {
                    if (err === 'OTP is required' && !this.showOtp) {
                        this.showOtp = true
                        this.loading = false
                        // this.$emit('twoFa')
                        return
                    }
                    if (err === 'Wrong OTP' || (err === 'OTP is required' && this.showOtp)) {
                        this.correctOtp = false
                        this.loading = false
                        return
                    }
                    eventBus.$emit('showSnackBar', err, 'error')
                })
            this.loading = false
        },
        updateValidateCode() {
            this.correctOTP = true
        }
    }
}
</script>
