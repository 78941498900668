<template>
    <div class="dashboard-wrap">
        <!--        <div-->
        <!--            v-if="getterLoadingFullMenu"-->
        <!--            class="dashboard-wrap__loader"-->
        <!--        >-->
        <!--            <v-progress-circular-->
        <!--                indeterminate-->
        <!--                color="#5e636e"-->
        <!--                :size="200"-->
        <!--                :width="4"-->
        <!--            />-->
        <!--        </div>-->
        <!--        <dashboard-card-->
        <!--            v-for="item in menu"-->
        <!--            :key="item.name"-->
        <!--            :item="item"-->
        <!--        />-->
        <div class="tw-flex tw-justify-center tw-items-center tw-flex-col">
            <p
                class="tw-text-3xl sm:tw-text-6xl tw-text-blue-500 tw-text-center tw-tracking-wider tw-p-4 tw-mx-2"
                style="font-variant: small-caps"
            >
                Admin  Freelancewriting  Center
            </p>
        </div>
    </div>
</template>

<script>
// import { createNamespacedHelpers, mapGetters } from 'vuex'

// import DashboardCard from '@/components/shared/Cards/DashboardCard'
// import {
//     ACTION_UPDATE_CLIENT_DATA
// } from '@/store/modules/client/action-types'

// import {
//     ACTION_GET_COUNTS
// } from '@/store/modules/dashboard/action-types'
import { DashboardMenuBuilder } from '@/services/dashboardService';

// const {
//     mapActions: mapClientActions
// } = createNamespacedHelpers('client')

// const {
//     mapActions: mapDashboardActions
// } = createNamespacedHelpers('dashboard')

export default {
    name: 'Dashboard',
    components: {
        // DashboardCard
    },
    data() {
        return {
            menu: null
        }
    },
    metaInfo: {
        title: 'Dashboard'
    },
    // computed: {
    //     ...mapGetters('dashboard', [
    //           'getFullMenu',
    //           'getterLoadingFullMenu'
    //     ])
    // },
    async created() {
        const dashboard = new DashboardMenuBuilder()
        // await dashboard.getCounters()
        this.menu = dashboard.fullMenu(dashboard.fullMenuWithoutPermissionAndPresets)
        // await this[ACTION_GET_COUNTS]()
        // await this[ACTION_UPDATE_CLIENT_DATA]()
    }
    // methods: {
    //     ...mapClientActions([
    //         ACTION_UPDATE_CLIENT_DATA
    //     ])
    //     ...mapDashboardActions([
    //         ACTION_GET_COUNTS
    //     ])
    // }
}
</script>

<style lang="scss">
    .dashboard-wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1750px;
        margin: 25px auto;
        position: relative;
        &__loader {
            position: absolute;
            top: 350px;
        }
    }
</style>
