<template>
    <div class="">
        <button
            class="btn-base btn-main"
            :class="validateParams ? 'btn-base btn-disabled' : 'btn-base btn-main'"
            @click="buttonClick"
        >
            <slot name="button-content" />
        </button>

        <div
            v-if="showRecaptha"
            class="modal_bg"
        >
            <div
                class="recaptcha_modal"
            >
                <div class="recaptcha_modal_header">
                    <h2>Confirm you are not a robot</h2>
                    <span @click="close">
                        <v-icon>mdi-close</v-icon>
                    </span>
                </div>
                <vue-recaptcha
                    v-show="recapthaLoaded"
                    ref="recaptcha"
                    :sitekey="recaptchaKey"
                    @render="renderMethod"
                    @verify="verify"
                />
                <v-btn
                    v-show="!recapthaLoaded"
                    color="primary"
                    loading
                />
            </div>
        </div>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    components: {
        VueRecaptcha
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        classes: {
            type: String,
            required: false
        },
        validateParams: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showRecaptha: false,
            recapthaLoaded: false
        }
    },
    computed: {
        recaptchaKey() {
            return process.env.VUE_APP_RECAPTCHA_KEY
        },
        disabledBtn() {
            return this.disabled ? 'btn-disabled' : ''
        }
    },
    methods: {
        close() {
            this.showRecaptha = false
        },
        renderMethod() {
            this.recapthaLoaded = true
        },
        buttonClick() {
            if (this.validateParams) {
                this.$emit('checkField')
            } else {
                this.showRecaptha = true
            }
        },
        verify(payload) {
            this.$emit('captchaVerify', payload)
            this.showRecaptha = false
        }
    }
}
</script>

<style lang="scss">
    .modal_bg{
        background: rgba($color: #000000, $alpha: 0.2);
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
    }
    .recaptcha_modal{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-100%) translateX(-50%);
        width: 350px;
        padding: 20px;
        background: #fff;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        z-index: 999;
        &_header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            h2 {
                color: #000;
            }
            svg {
                cursor: pointer;
                fill: #000;
                transition: .3s all;
                // &:hover {
                //     fill: $main-color;
                // }
            }
        }
    }
</style>
