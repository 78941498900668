<template>
    <div class="wrapper mt-5 clients-page actions-table">
        <v-container
            fluid
            class="white rounded pa-0"
        >
            <the-main-filter
                :search-fields="search_fields"
                :search-values="searchOptions"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                >
                    <v-btn
                        color="success"
                        class="mr-3"
                        @click.stop="createPermission"
                    >
                        Create
                    </v-btn>
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'admins'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Admins
                        </router-link>
                    </v-hover>
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'roles'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Roles
                        </router-link>
                    </v-hover>
                </template>
            </the-main-filter>

            <v-data-table
                :headers="tableHeader"
                :items="getterPermissionsList"
                item-key="id"
                :server-items-length="getterTotal"
                :loading="loading"
                hide-default-footer
                calculate-widths
                class="mt-10"
            >
                <template v-slot:body="{ items }">
                    <table-skeleton
                        v-if="loading && (items && items.length === 0)"
                        :per-page="searchOptions.per_page"
                        :column-count="tableHeaderLength"
                    />
                    <table-loader
                        v-if="loading"
                    />
                    <tbody>
                        <tr
                            v-for="item in items"
                            :key="item.id"
                            class="hovered-row"
                            :class="selectRow(item.id)"
                        >
                            <!-- ID -->
                            <td>
                                {{ item.id }}
                            </td>
                            <!-- NAME -->
                            <td>
                                {{ item.name }}
                            </td>
                            <!-- CREATED -->
                            <td>
                                {{ item.created_at && $date.humanizeDate(item.created_at) }}
                            </td>
                            <!-- ACTIONS -->
                            <td>
                                <table-rows-menu
                                    :toolbars="itemToolbars"
                                    :item="item"
                                    class="mt-0"
                                    @menuActivated="onMenuActivated"
                                />
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>

            <div
                v-if="!getterPermissionsList.length && !loading"
                class="d-flex flex-column align-center w-full mt-5 mb-5"
            >
                <div class="">
                    <img
                        src="@images/svg/no-data.svg"
                        alt=""
                    >
                </div>
                <p class="text-h6">
                    No permissions found
                </p>
            </div>

            <pagination-with-perpage
                v-if="!loading"
                :list-lenght="getterPermissionsList.length"
                :last-page="getterLastPage"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            />

            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                :current-item="currentItem"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import TableLoader from '@/components/shared/Tables/TableLoader.vue';
import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import TheFilterPreset from '@/components/shared/TableFilters/TheFilterPreset'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'
import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'

import {
    PERMISSIONS_ITEM_TOOLBARS,
    PERMISSIONS_TABLE_HEADER
} from '@/constants/Permissions'

import {
    PERMISION_LIST_SEARCH
} from '@/constants/Permissions/filter'

import {
    ACTION_CREATE_PERMISSION,
    ACTION_DELETE_PERMISSIONS,
    ACTION_GET_PERMISSIONS_LIST,
    ACTION_UPDATE_PERMISSION
} from '@/store/modules/permissions/action-types';

import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalPermission from '@/components/shared/Modals/ModalPermission'
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';

const {
    mapActions: mapPermissionsActions
} = createNamespacedHelpers('permissions');

export default {
    name: 'Permissions',
    components: {
        TheMainFilter,
        TheFilterPreset,
        ModalAlert,
        ModalPermission,
        ToolbarMenu,
        PaginationWithPerpage,
        TableSkeleton,
        TableRowsMenu,
        TableLoader
    },
    data() {
        return {
            selected: [],
            loading: true,
            modal: {},
            currentItem: {},
            actionName: '',
            currentAction: '',
            currentId: null,
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: 'name',
                sort_order: 'DESC'
            },
            singleAction: false
        }
    },
    computed: {
        ...mapGetters('permissions', [
            'getterPermissionsList',
            'getterLastPage',
            'getterTotal'
        ]),
        tableHeader() {
            return PERMISSIONS_TABLE_HEADER
        },
        tableHeaderLength() {
            return PERMISSIONS_TABLE_HEADER.length
        },
        modalComponent() {
            return this.modal?.name || ''
        },
        itemToolbars() {
            return PERMISSIONS_ITEM_TOOLBARS
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        search_fields() {
            return PERMISION_LIST_SEARCH
        }
    },
    metaInfo: {
        title: 'Permissions'
    },
    created() {
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
    },
    methods: {
        ...mapPermissionsActions({
            ACTION_GET_PERMISSIONS_LIST,
            ACTION_DELETE_PERMISSIONS,
            ACTION_UPDATE_PERMISSION,
            ACTION_CREATE_PERMISSION
        }),
        createPermission() {
            this.modal = {
                name: 'ModalPermission',
                title: 'Create a permission'
            }
            this.currentItem = {}
            this.actionName = 'create'
            this.singleAction = false
        },
        async getPermissionList() {
            if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                this.$router.replace({ query: { ...this.searchOptions } })
            }
            try {
                this.loading = true
                await this[ACTION_GET_PERMISSIONS_LIST]({ ...this.searchOptions })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getPermissionList()
        },
        async onFilterTable(payload) {
            this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            await this.getPermissionList()
        },
        async onSortTable(flag, option) {
            if (flag) {
                this.searchOptions = {
                    ...this.searchOptions,
                    ...option
                }
            } else {
                this.searchOptions = {
                    ...this.searchOptions,
                    ...option,
                    page: 1
                }
            }
            await this.getPermissionList()
        },
        onToolbarActivated(payload) {
            this.singleAction = false
            this.actionName = payload.type
            this.modal = payload.modal
        },
        onMenuActivated(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentId = item.id
            this.currentItem = item
            this.singleAction = true
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
            this.singleAction = false
        },
        selectRow(id) {
            return {
                'table-row--selected': this.selected.some((item) => item?.id === id),
                'table-row--selected-once': this.currentItem?.id === id
            }
        },
        async deletePermissions() {
            await this[ACTION_DELETE_PERMISSIONS](this.currentItem.id)
            eventBus.$emit('showSnackBar', 'Permission has been deleted', 'success')
            this.selected = []
            this.getPermissionList()
        },
        async editPermission(name) {
            await this[ACTION_UPDATE_PERMISSION]({
                name,
                id: this.currentItem.id
            })
            eventBus.$emit('showSnackBar', 'Permission has been edited', 'success')
        },
        async createNewPermission(name) {
            await this[ACTION_CREATE_PERMISSION]({ name })
            eventBus.$emit('showSnackBar', 'Permission has been created', 'success')
        },
        async onConfirmModal(name) {
            try {
                if (this.actionName === 'delete') {
                    await this.deletePermissions()
                }
                if (this.actionName === 'edit') {
                    await this.editPermission(name)
                }
                if (this.actionName === 'create') {
                    await this.createNewPermission(name)
                }
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        seletedToIdsList() {
            return this.selected.map((item) => item.id)
        }
    }
}
</script>
<style lang="scss" scoped>
.loader{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 999;
}
</style>
